import React, { useEffect, useState } from "react";
import MainToolbar from "components/MainToolbar";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Paper,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "common/components/LocalizationProvider";

import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { AddCircle, Edit, Save } from "@mui/icons-material";
import { DeleteView, EditView, MapContainer } from "GeofencesTab";
import _ from "lodash";
import { convertUnicode, useEffectAsync } from "../reactHelper";
import SelectField from "common/components/SelectField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from "react-google-autocomplete";
import { geofenceToFeatureV2, geometryToArea } from "../map/core/mapUtil";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiAlert-filledError": {
      backgroundColor: "#d32f2f !important",
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  columnAction: {
    // width: theme.spacing(1),
    // padding: theme.spacing(0, 1),
  },
  autocompleteLabel: {
    fontSize: "14px",
    marginTop: "15px",
    background: "#1d1b28",
    padding: "5px 0px 0px 10px",
  },
  autocompleteInput: {
    color: "#fff",
    background: "#1d1b28",
    border: "0px",
    borderBottom: "1px solid #000",
    padding: "5px 20px 10px 10px",
    fontSize: "17px",
    "&:focus": {
      borderColor: theme.palette.primary.main, // Changes border color on focus
      boxShadow: `0 0 5px ${theme.palette.primary.main}`, // Adds a glow effect on focus
    },
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GeoFencesPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isDeleteTriggered, setIsDeleteTriggered] = useState(false);
  const [SelectedRow, setSelectedRow] = useState();
  const [items, setItems] = useState([]);
  const [Type, setType] = useState("Current Location");
  const [CurrentDeviceCoordinates, setCurrentDeviceCoordinates] = useState();
  const [showMapBox, setShowMapBox] = useState(false);
  const [showGeofenceTable, setShowGeofenceTable] = useState(false);
  const [Address, setAddress] = useState();
  const [IsEdit, setIsEdit] = useState(false);
  const [Push, setPush] = useState(true);
  const [SMS, setSMS] = useState(false);
  const [Web, setWeb] = useState(false);
  const [Mail, setMail] = useState(false);
  const [GeoFencesData, setGeoFencesData] = useState({
    id: 0,
    name: "",
    description: "",
    area: "600",
    calendarId: 0,
    attributes: {},
  });
  const [EnterFence, setEnterFence] = useState(false);
  const [showPhoneDialog, setShowPhoneDialog] = useState(false);
  const [EnterFenceID, setEnterFenceID] = useState();
  const [LeaveFenceID, setLeaveFenceID] = useState();
  const [LeaveFence, setLeaveFence] = useState(false);
  const [polygonType, setPolygonType] = useState("Circle");
  const [polygonData, setPolygonData] = useState();
  const [NotificationObject, setNotificationObject] = useState({
    id: -1,
    always: false,
    type: null,
    calendarId: 0,
    notificators: "mail,web",
  });
  const selectedDevice = useSelector((state) => state.devices.selectedItem);
  const selectedDeviceId = selectedDevice ? selectedDevice.id : null;
  const user = useSelector((state) => state.session.user);
  const devices = useSelector((state) => Object.values(state.devices.items));
  const [deviceId, setDeviceId] = useState(selectedDeviceId);
  const userId = useSelector(
    (state) => state.session.user && state.session.user.id
  );
  const phone = useSelector(
    (state) => state.session.user && state.session.user.phone
  );

  const height = window.innerHeight;

  const handleSuccessPhoneCallback = () => {
    alert("handle success phone dialog");
  };

  const handleErrorPhoneCallback = () => {
    setShowPhoneDialog(false);
    EditAddGeofences();
  };

  const formReset = () => {
    setIsEdit(false);
    setSelectedRow(null);
    setPolygonData(null);
    setPolygonType("Circle");
    // setIsFormVisible(false);
    // setGeoFencesData({
    //   id: 0,
    //   name: "",
    //   description: "",
    //   area: "600",
    //   calendarId: 0,
    //   attributes: {},
    // });
  };

  const handleDraw = (data) => {
    setPolygonData(geometryToArea(data?.geometry));
  };

  const handleClick = async (mail, json) => {
    setShowGeofenceTable(true);
    // setShowMapBox(true);
    GetAllGeofences();
  };

  const selectedDevicePositionId = useSelector((state) => {
    if (deviceId) {
      const position = state.session.positions[deviceId] || null;
      if (position) {
        return position.id;
      }
    }
  });

  const EditGeofences = async () => {
    const newObj = { ...GeoFencesData };

    if (
      !!CurrentDeviceCoordinates.latitude &&
      !!CurrentDeviceCoordinates.longitude
    ) {
      newObj.attributes.latitude = CurrentDeviceCoordinates.latitude;
      newObj.attributes.longitude = CurrentDeviceCoordinates.longitude;
    }

    newObj.area =
      polygonType == "Circle"
        ? `CIRCLE(${CurrentDeviceCoordinates.latitude} ${CurrentDeviceCoordinates.longitude},${newObj.area})`
        : polygonData;

    const response = await fetch(`/api/geofences/${newObj.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newObj),
    });
    // debugger;
    if (response.ok) {
      setIsFormVisible(false);
      setIsEdit(false);
      setShowMapBox(false);
      setSelectedRow();
      formReset();

      GetAllGeofences();
      F_EnterFence();
      F_LeaveFence();
    }
  };

  const EditAddGeofences = () => {
    if (IsEdit) {
      EditGeofences();
    } else {
      AddGeofences();
    }
  };

  const validatePhoneNumber = () => {
    if (SMS && (!phone || phone.length == 0)) {
      setShowPhoneDialog(true);
      setSMS(false);
      GenerateNotificationObject();
    } else {
      EditAddGeofences();
    }
  };

  const AddGeofences = async () => {
    const newObj = { ...GeoFencesData };
    newObj.attributes.polygonType = polygonType;
    newObj.attributes.latitude = CurrentDeviceCoordinates.latitude;
    newObj.attributes.longitude = CurrentDeviceCoordinates.longitude;

    newObj.area = polygonType == "Circle"
      ? `CIRCLE(${CurrentDeviceCoordinates.latitude} ${CurrentDeviceCoordinates.longitude},${newObj.area})` : polygonData;

    const response = await fetch("/api/geofences", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newObj),
    });
    // debugger;
    if (response.ok) {
      const resp = await response.json();

      const respe = await fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId,
          geofenceId: resp.id,
        }),
      });
      if (respe.ok) {
        setIsFormVisible(false);
        setIsEdit(false);
        setShowMapBox(false);
        setSelectedRow();
        formReset();
        GetAllGeofences();
        // Update Notification

        F_EnterFence();
        F_LeaveFence();
      }
    }
  };

  const GetAllGeofences = async (afterDelete = false) => {
    const response = await fetch(`/api/geofences?deviceId=${deviceId}`);
    if (response.ok) {
      const itemData = await response.json();
      if (!itemData.length) {
        setShowMapBox(false);
      }
      setItems(itemData);
    }
  };

  const getAddress = (longitude, latitude, setOnlyAddress) => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_LIVE_MAP_BOX_TOKEN}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result?.features) {
            setAddress(result?.features[0]?.place_name);
            if (!setOnlyAddress) {
              setGeoFencesData({
                ...GeoFencesData,
                description: result?.features[0]?.place_name,
              });
            }
          }
        },
        (error) => {
          // console.log(error);
        }
      );
  };

  const resetFormCheckBoxes = () => {
    if (!SelectedRow) {
      setSMS(false);
      setWeb(false);
      setMail(false);
      setLeaveFence(false);
      setEnterFence(false);
    }
  };
  const GenerateNotificationObject = () => {
    const notificatorItems = [];

    if (SMS) {
      notificatorItems.push("sms");
    }

    if (Mail) {
      notificatorItems.push("mail");
    }

    if (Web) {
      notificatorItems.push("web");
    }

    const notificationData = {
      id: deviceId || -1,
      always: false,
      type: null,
      calendarId: 0,
      notificators: notificatorItems.join(","),
    };
    setNotificationObject(notificationData);
  };

  useEffect(() => {
    GenerateNotificationObject();
  }, [SMS, Mail, Web]);

  useEffectAsync(async () => {
    if (deviceId) {
      const res = await fetch(`/api/notifications?deviceId=${deviceId}`, {
        method: "GET",
      });

      if (res.ok) {
        const data = await res.json();
        let geofenceEnterNotification = _.orderBy(
          _.filter(data, { type: "geofenceEnter" }),
          ["id"],
          ["desc"]
        );
        let geofenceEnterOutput = _.first(geofenceEnterNotification);

        if (!!geofenceEnterOutput) {
          setEnterFenceID(geofenceEnterOutput.id);
          setEnterFence(true);

          if (!!geofenceEnterOutput.notificators) {
            let notificatorsTemp = geofenceEnterOutput.notificators.split(",");

            setMail(notificatorsTemp.includes("mail"));
            setWeb(notificatorsTemp.includes("web"));
            setSMS(notificatorsTemp.includes("sms"));
            setPush(notificatorsTemp.includes("firebase"));
          }
        }

        let geofenceExitNotification = _.orderBy(
          _.filter(data, { type: "geofenceExit" }),
          ["id"],
          ["desc"]
        );
        let geofenceExitOutput = _.first(geofenceExitNotification);

        if (!!geofenceExitOutput) {
          setLeaveFenceID(geofenceExitOutput.id);
          setLeaveFence(true);

          if (geofenceExitOutput.notificators) {
            let notificatorsTemp = geofenceExitOutput.notificators.split(",");
            setMail(notificatorsTemp.includes("mail"));
            setWeb(notificatorsTemp.includes("web"));
            setSMS(notificatorsTemp.includes("sms"));
            setPush(notificatorsTemp.includes("firebase"));
          }
        }
      }
    }
  }, [deviceId]);

  useEffectAsync(async () => {
    if (selectedDevicePositionId) {
      if (Type === "Current Location") {
        const response = await fetch(
          `/api/positions?id=${selectedDevicePositionId}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        if (response.ok) {
          // console.log("step 1");
          const items = await response.json();
          // console.log(items[0].longitude, items[0].latitude);
          getAddress(items[0].longitude, items[0].latitude);
          // console.log("step 3");

          setCurrentDeviceCoordinates(items[0]);
        }
      }
    } else {
      setCurrentDeviceCoordinates({});
    }
    await GetAllGeofences();
  }, [selectedDevicePositionId, Type]);

  const F_EnterFence = async () => {
    if (EnterFence) {
      if (EnterFenceID) {
        await fetch(`/api/notifications/${EnterFenceID}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...NotificationObject,
            id: EnterFenceID,
            type: "geofenceEnter",
          }),
        })
          .then((res) => res.json())
          .then(async (result) => {
            setEnterFenceID(result.id);
            if (result) {
              GetAllGeofences();
            }
          });
      } else {
        await fetch("/api/notifications", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...NotificationObject,
            type: "geofenceEnter",
          }),
        })
          .then((res) => res.json())
          .then(async (result) => {
            setEnterFenceID(result.id);
            setEnterFence(true);

            const respe = await fetch("/api/permissions", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                deviceId: deviceId,
                notificationId: result.id,
              }),
            });
            if (respe) {
              GetAllGeofences();
            }
          });
      }
    } else {
      if (EnterFenceID) {
        await fetch(
          `/api/notifications/` + EnterFenceID + `?_dc=${new Date().getTime()}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ...NotificationObject,
              type: "geofenceEnter",
              id: EnterFenceID,
            }),
          }
        );

        const respe = await fetch("/api/permissions", {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: deviceId,
            notificationId: EnterFenceID,
          }),
        });
      }
      GetAllGeofences();
    }
  };

  const F_LeaveFence = async () => {
    if (LeaveFence) {
      if (LeaveFenceID) {
        await fetch(`/api/notifications/${LeaveFenceID}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...NotificationObject,
            id: LeaveFenceID,
            type: "geofenceExit",
          }),
        })
          .then((res) => res.json())
          .then(async (result) => {
            setLeaveFenceID(result.id);
            if (result) {
              GetAllGeofences();
            }
          });
      } else {
        await fetch("/api/notifications", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...NotificationObject, type: "geofenceExit" }),
        })
          .then((res) => res.json())
          .then(async (result) => {
            setLeaveFenceID(result.id);
            setLeaveFence(true);

            const respe = await fetch("/api/permissions", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                deviceId: deviceId,
                notificationId: result.id,
              }),
            });
            if (respe) {
              GetAllGeofences();
            }
          });
      }
    } else {
      if (LeaveFenceID) {
        await fetch(
          `/api/notifications/` + LeaveFenceID + `?_dc=${new Date().getTime()}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ...NotificationObject,
              type: "geofenceExit",
              id: LeaveFenceID,
            }),
          }
        );

        const respe = await fetch("/api/permissions", {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: deviceId,
            notificationId: LeaveFenceID,
          }),
        });
      }
    }

    GetAllGeofences();
  };

  return (
    <div className={classes.root}>
      <MainToolbar />
      <Snackbar
        open={showPhoneDialog}
        autoHideDuration={6000}
        onClose={() => {
          setShowPhoneDialog(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowPhoneDialog(false);
          }}
          sx={{ background: "initial" }}
          severity="error"
        >
          Please enter phone number in accounts
        </Alert>
      </Snackbar>
      <div className={classes.content}>
        <Grid container spacing={2}>
          {/* {true && ( */}
          <>
            <Grid item xs={12} md={3} lg={2}>
              <Paper className={classes.form}>
                <ButtonGroup>
                  {isFormVisible ? (
                    <Button
                      color="secondary"
                      onClick={() => {
                        setIsFormVisible(false);
                        setIsEdit(false);
                        setShowMapBox(false);
                        setSelectedRow();
                      }}
                    >
                      Close
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<AddCircle />}
                      onClick={() => {
                        setGeoFencesData({
                          ...GeoFencesData,
                          id: 0,
                          name: "",
                          area: "600",
                          calendarId: 0,
                          attributes: {},
                        });
                        setShowMapBox(true);
                        setIsFormVisible(true);
                      }}
                    >
                      {IsEdit ? "Edit Geofence" : "Add Geofence"}
                    </Button>
                  )}
                </ButtonGroup>
                <>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Vehicle</InputLabel>

                    <Select
                      value={deviceId}
                      onChange={(e) => {
                        setDeviceId(e.target.value);
                        setSelectedRow(null);
                        setIsEdit(false);
                      }}
                      MenuProps={{
                        classes: { paper: classes.dropdownStyle },
                      }}
                    >
                      {_.orderBy(
                        devices,
                        [(item) => item.name.toLowerCase()],
                        ["asc"]
                      ).map((device) => (
                        <MenuItem value={device.id} key={device.id}>
                          {convertUnicode(device.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <ButtonGroup
                      color="primary"
                      orientation="vertical"
                      disabled={!true}
                    >
                      <Button onClick={() => handleClick(false, true)}>
                        {t("reportShow")}
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                  {isFormVisible && !isDeleteTriggered && (
                    <>
                      <FormControl variant="filled" margin="normal" fullWidth>
                        <InputLabel>Select Type</InputLabel>
                        <Select
                          value={Type}
                          onChange={(event) => {
                            setType(event.target.value);
                          }}
                          MenuProps={{
                            classes: { paper: classes.dropdownStyle },
                          }}
                        // value={GeoFencesData.description}
                        >
                          <MenuItem value="Current Location">
                            Current Location
                          </MenuItem>
                          <MenuItem value="Search Location">
                            Search Location
                          </MenuItem>
                        </Select>
                        {Type == "Search Location" && (
                          <>
                            <label className={classes.autocompleteLabel}>
                              Search for Location
                            </label>
                            <Autocomplete
                              className={classes.autocompleteInput}
                              apiKey={
                                process.env.REACT_APP_LIVE_GOOGLE_MAPS_API_KEY
                              }
                              options={{
                                types: ["geocode"],
                                componentRestrictions: { country: "au" },
                              }}
                              onPlaceSelected={(place) => {
                                setAddress(place.formatted_address);
                                setCurrentDeviceCoordinates({
                                  latitude: place.geometry.location.lat(),
                                  longitude: place.geometry.location.lng(),
                                });
                                setGeoFencesData({
                                  ...GeoFencesData,
                                  description: place.formatted_address,
                                });
                              }}
                            />
                          </>
                        )}
                      </FormControl>
                      <FormControl variant="filled" margin="normal" fullWidth>
                        <InputLabel>Select Area Type</InputLabel>
                        <Select
                          value={polygonType}
                          onChange={(event) => {
                            setPolygonType(event.target.value);
                          }}
                          readOnly={IsEdit}
                          MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                        // value={GeoFencesData.description}
                        >
                          <MenuItem value="Circle">Circle</MenuItem>
                          <MenuItem value="Custom">Custom</MenuItem>
                        </Select>
                      </FormControl>
                      {polygonType == "Circle" && (
                        <FormControl variant="filled" margin="normal" fullWidth>
                          <InputLabel>Select Radius</InputLabel>
                          <Select
                            value={GeoFencesData.area}
                            onChange={(e) =>
                              setGeoFencesData({
                                ...GeoFencesData,
                                area: e.target.value,
                              })
                            }
                            MenuProps={{
                              classes: { paper: classes.dropdownStyle },
                            }}
                          >
                            {/* <MenuItem value="200">200 metres</MenuItem> */}
                            <MenuItem value="600">600 metres</MenuItem>
                            <MenuItem value="800">800 metres</MenuItem>
                            <MenuItem value="1000">1,000 metres</MenuItem>
                            <MenuItem value="1100">1,100 metres</MenuItem>
                            <MenuItem value="1200">1,200 metres</MenuItem>
                            <MenuItem value="1300">1,300 metres</MenuItem>
                            <MenuItem value="1400">1,400 metres</MenuItem>
                            <MenuItem value="1500">1,500 metres</MenuItem>
                            <MenuItem value="2000">2,000 metres</MenuItem>
                            <MenuItem value="3000">3,000 metres</MenuItem>
                            <MenuItem value="25000">25 kilometres</MenuItem>
                            <MenuItem value="50000">50 kilometres</MenuItem>
                            <MenuItem value="100000">100 kilometres</MenuItem>
                          </Select>
                        </FormControl>
                      )}

                      {deviceId && (
                        <>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={EnterFence}
                                  onChange={(e) =>
                                    setEnterFence(e.target.checked)
                                  }
                                  name="EnterFence"
                                />
                              }
                              label="Enter Fence"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={LeaveFence}
                                  onChange={(e) =>
                                    setLeaveFence(e.target.checked)
                                  }
                                  name="LeaveFence"
                                />
                              }
                              label="Leave Fence"
                            />
                          </FormGroup>
                          <br />
                          <FormControl
                            sx={{ m: 3 }}
                            component="fieldset"
                            variant="standard"
                          >
                            <FormLabel component="legend">
                              Notification Type
                            </FormLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={Mail}
                                    onChange={(e) => setMail(e.target.checked)}
                                    name="mail"
                                  />
                                }
                                label="Mail"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={Web}
                                    onChange={(e) => setWeb(e.target.checked)}
                                    name="web"
                                  />
                                }
                                label="Web"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={SMS}
                                    onChange={(e) => setSMS(e.target.checked)}
                                    name="sms"
                                  />
                                }
                                label="SMS"
                              />
                              {
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={Push}
                                      onChange={(e) =>
                                        setPush(e.target.checked)
                                      }
                                      name="firebase"
                                    />
                                  }
                                  label="Push"
                                />
                              }
                            </FormGroup>
                          </FormControl>
                        </>
                      )}

                      <TextField
                        margin="normal"
                        label="Geofence name"
                        variant="filled"
                        onChange={(e) =>
                          setGeoFencesData({
                            ...GeoFencesData,
                            name: e.target.value,
                          })
                        }
                        value={GeoFencesData.name}
                        fullWidth
                      />
                      {user?.attributes?.calendar && (
                        <SelectField
                          margin={"normal"}
                          value={GeoFencesData.calendarId || 0}
                          variant="filled"
                          fullWidth
                          onChange={(event) =>
                            setGeoFencesData({
                              ...GeoFencesData,
                              calendarId: Number(event.target.value),
                            })
                          }
                          endpoint="/api/calendars"
                          label={t("sharedCalendar")}
                        />
                      )}

                      {GeoFencesData.name &&
                        Address &&
                        GeoFencesData.area &&
                        (IsEdit ? (
                          <div className={classes.btn}>
                            <ButtonGroup>
                              <Button
                                onClick={() => validatePhoneNumber()}
                                color="primary"
                                startIcon={<Edit />}
                              >
                                Save Changes
                              </Button>
                            </ButtonGroup>
                          </div>
                        ) : (
                          <div className={classes.btn}>
                            <ButtonGroup color="primary">
                              <Button
                                onClick={() => validatePhoneNumber()}
                                color="primary"
                                startIcon={<Save />}
                              >
                                Save
                              </Button>
                            </ButtonGroup>
                          </div>
                        ))}
                    </>
                  )}
                </>
              </Paper>
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <div style={{ height: height - 100 }}>
                {Type && showMapBox && deviceId && (
                  <div style={{ height: "50%", marginTop: 2, marginBottom: 2 }}>
                    <div
                      style={{
                        height: height / 2 - 50,
                        position: "relative",
                      }}
                    >
                      <MapContainer
                        height={height / 2 - 50}
                        Type={Type}
                        key={
                          CurrentDeviceCoordinates
                            ? CurrentDeviceCoordinates.latitude
                            : 0
                        }
                        latitude={
                          CurrentDeviceCoordinates
                            ? CurrentDeviceCoordinates.latitude
                            : 0
                        }
                        longitude={
                          CurrentDeviceCoordinates
                            ? CurrentDeviceCoordinates.longitude
                            : 0
                        }
                        // radius={radius}
                        Address={Address}
                        area={Number(GeoFencesData.area)}
                        polygonType={polygonType}
                        polygonData={polygonData}
                        geofence={GeoFencesData}
                        onDraw={(data) => handleDraw(data)}
                      />
                    </div>
                  </div>
                )}

                {showGeofenceTable ? (
                  <div style={{ height: "50%", marginTop: 2, marginBottom: 2 }}>
                    <div>
                      <MaterialTable
                        columns={[
                          {
                            title: "Geofence Name",
                            field: "name",
                            render: (rowData) => rowData.name,
                          },
                          {
                            title: "Radius",
                            field: "area",
                            render: (rowData) => {
                              if (rowData.area.startsWith("CIRCLE")) {
                                let area = rowData.area
                                  .split(")")[0]
                                  .split(",")[1];
                                return area > 20000
                                  ? area / 1000 + " kms"
                                  : area + " m";
                              }
                            },
                          },
                          {
                            title: "Address",
                            field: "description",
                            render: (rowData) => rowData.description,
                          },
                          {
                            title: "Action",
                            field: "Delete",
                            render: (rowData) => {
                              const DeleteCb = async (e) => {
                                setIsDeleteTriggered(true);
                                const response = await fetch(
                                  `/api/geofences/${rowData.id}`,
                                  {
                                    method: "DELETE",
                                  }
                                );
                                // await  fetch("/api/permissions", {
                                //   method: "DELETE",
                                //   headers: { "Content-Type": "application/json" },
                                //   body: JSON.stringify({
                                //     deviceId: deviceId,
                                //     geofenceId: rowData.id,
                                //   }),
                                // });
                                //
                                // await  fetch("/api/permissions", {
                                //   method: "DELETE",
                                //   headers: { "Content-Type": "application/json" },
                                //   body: JSON.stringify({
                                //     userId: userId,
                                //     geofenceId: rowData.id,
                                //   }),
                                // });

                                if (LeaveFenceID) {
                                  await fetch(
                                    `/api/notifications/${LeaveFenceID}?_dc=${new Date().getTime()}`,
                                    {
                                      method: "DELETE",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        ...NotificationObject,
                                        type: "geofenceExit",
                                        id: LeaveFenceID,
                                      }),
                                    }
                                  );

                                  // await fetch("/api/permissions", {
                                  //   method: "DELETE",
                                  //   headers: { "Content-Type": "application/json" },
                                  //   body: JSON.stringify({
                                  //     deviceId: deviceId,
                                  //     notificationId: LeaveFenceID,
                                  //   }),
                                  // });
                                  //
                                  // await fetch("/api/permissions", {
                                  //   method: "DELETE",
                                  //   headers: { "Content-Type": "application/json" },
                                  //   body: JSON.stringify({
                                  //     deviceId: deviceId,
                                  //     userId: LeaveFenceID,
                                  //   }),
                                  // });
                                  //
                                  // const respe =await fetch("/api/permissions", {
                                  //   method: "DELETE",
                                  //   headers: { "Content-Type": "application/json" },
                                  //   body: JSON.stringify({
                                  //     userId: userId,
                                  //     notificationId: LeaveFenceID,
                                  //   }),
                                  // });
                                  //
                                  // await fetch("/api/permissions", {
                                  //   method: "DELETE",
                                  //   headers: { "Content-Type": "application/json" },
                                  //   body: JSON.stringify({
                                  //     deviceId: deviceId,
                                  //     userId: userId,
                                  //   }),
                                  // });

                                  //      await  fetch("/api/permissions", {
                                  //     method: "DELETE",
                                  //     headers: { "Content-Type": "application/json" },
                                  //     body: JSON.stringify({
                                  //       deviceId: deviceId,
                                  //       notificationId: LeaveFenceID,
                                  //     }),
                                  //   });

                                  //  await fetch( `/api/notifications/`+LeaveFenceID+`?_dc=${new Date().getTime()}`,{
                                  //     method:"DELETE",
                                  //     headers: { 'Content-Type': 'application/json' },
                                  //     body:JSON.stringify({...NotificationObject,type:"geofenceExit",id:LeaveFenceID})
                                  //   })
                                }

                                if (EnterFenceID) {
                                  await fetch(
                                    `/api/notifications/${EnterFenceID}?_dc=${new Date().getTime()}`,
                                    {
                                      method: "DELETE",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        ...NotificationObject,
                                        type: "geofenceEnter",
                                        id: EnterFenceID,
                                      }),
                                    }
                                  );

                                  // const respe =await  fetch("/api/permissions", {
                                  //   method: "DELETE",
                                  //   headers: { "Content-Type": "application/json" },
                                  //   body: JSON.stringify({
                                  //     deviceId: deviceId,
                                  //     notificationId: EnterFenceID,
                                  //   }),
                                  // });

                                  // await fetch("/api/permissions", {
                                  //   method: "DELETE",
                                  //   headers: { "Content-Type": "application/json" },
                                  //   body: JSON.stringify({
                                  //     deviceId: deviceId,
                                  //     userId: EnterFenceID,
                                  //   }),
                                  // });
                                  //
                                  // await fetch("/api/permissions", {
                                  //   method: "DELETE",
                                  //   headers: { "Content-Type": "application/json" },
                                  //   body: JSON.stringify({
                                  //     userId: userId,
                                  //     notificationId: EnterFenceID,
                                  //   }),
                                  // });
                                  //
                                  // await fetch("/api/permissions", {
                                  //   method: "DELETE",
                                  //   headers: { "Content-Type": "application/json" },
                                  //   body: JSON.stringify({
                                  //     deviceId: deviceId,
                                  //     userId: userId,
                                  //   }),
                                  // });
                                }

                                if (response) {
                                  formReset();
                                  setIsFormVisible(false);
                                  setIsDeleteTriggered(false);
                                  e.stopPropagation();
                                  GetAllGeofences(true);
                                }
                                e.stopPropagation();
                              };
                              return (
                                <div style={{ display: "flex" }}>
                                  <DeleteView cb={DeleteCb} />
                                </div>
                              );
                            },
                          },
                        ]}
                        data={items.sort((a, b) =>
                          a.id < b.id ? 1 : b.id < a.id ? -1 : 0
                        )}
                        title=""
                        onRowClick={(event, rowData) => {
                          setPolygonData(rowData.area);

                          if (rowData?.attributes?.polygonType == "Custom") {
                            const area = rowData.area;
                            const latitude = rowData?.attributes?.latitude;
                            const longitude = rowData?.attributes?.longitude;
                            getAddress(longitude, latitude, true);
                            setCurrentDeviceCoordinates({
                              latitude: Number(latitude),
                              longitude: Number(longitude),
                            });
                            setPolygonType("Custom");
                            setGeoFencesData({
                              ...GeoFencesData,
                              id: rowData.id,
                              name: rowData.name,
                              description: rowData.description,
                              area,
                              calendarId: rowData.calendarId,
                              attributes: rowData.attributes,
                            });
                          } else {
                            const area = rowData.area.split(")")[0].split(",")[1];
                            const latitude = rowData.area
                              .split("(")[1]
                              .split(")")[0]
                              .split(",")[0]
                              .split(" ")[0];
                            const longitude = rowData.area
                              .split("(")[1]
                              .split(")")[0]
                              .split(",")[0]
                              .split(" ")[1];

                            getAddress(longitude, latitude, true);
                            setPolygonType("Circle");
                            setCurrentDeviceCoordinates({
                              latitude: Number(latitude),
                              longitude: Number(longitude),
                            });


                            setGeoFencesData({
                              ...GeoFencesData,
                              id: rowData.id,
                              name: rowData.name,
                              description: rowData.description,
                              area,
                              calendarId: rowData.calendarId,
                              attributes: {},
                            });
                          }

                          setSelectedRow(rowData.tableData.id);
                          setIsEdit(true);
                          setShowMapBox(true);
                          setIsFormVisible(true);
                          setType("Search Location");
                          setAddress(rowData.description);
                        }}
                        options={{
                          headerStyle: {
                            backgroundColor: "#1F1D2B",
                            color: "#FFFFFF",
                          },
                          sorting: true,
                          paging: false,
                          /* maxBodyHeight: calcHeight+'px', */
                          defaultSort: "desc",
                          rowStyle: (rowData) => ({
                            backgroundColor:
                              SelectedRow === rowData.tableData.id
                                ? "#0d0d0d"
                                : "",
                          }),
                          search: true,
                        }}
                        style={{
                          color: "#57c7d4",
                          overflowY: "scroll",
                          height: height / 2 - 50,
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </Grid>
          </>
          {/* )} */}
        </Grid>
      </div>
    </div>
  );
};

export default GeoFencesPage;
