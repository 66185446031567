import { useTranslation } from "common/components/LocalizationProvider";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TextField from "@mui/material/TextField";
import {
  Button,
  ButtonGroup,
  MenuItem,
  Menu,
  Chip,
  Checkbox,
  ListItemText,
  Grid,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { orange, green, red, blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { formatDateAustralian } from "common/formatter";
import { devicesActions, notificationActions, settingActions } from "store/";
import { ApiUrl } from "const";
import { speedToKnots } from "common/util/converter";
import {
  CustomAlertBox,
  convertUnicode,
  DialogBox,
  useEffectAsync,
} from "reactHelper";
import LinearProgress from "@mui/material/LinearProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import { AlarmAddOutlined } from "@mui/icons-material";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MaterialTable from "material-table";
import GroupIcon from "@mui/icons-material/Group";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    padding: "10px",
    "& .MuiSwitch-switchBase": {
      color: "#f30707",
    },
    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: "#3f51b5",
    },
  },
  paperRoot: {
    height: "100%",
    padding: "10px",
    "& .MuiSwitch-switchBase": {
      color: "#f30707",
    },
    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: "#3f51b5",
    },
  },
  selectbtn: {
    textTransform: "initial",
  },
  btn: {
    "& div": {
      margin: "5px",
    },
  },
  chip: {
    margin: 2,
    backgroundColor: "#616161",
    color: "#fff",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  orange: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
  },
  red: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  menuStyle: {
    "& ul": {
      border: "1px solid black",
      borderRadius: "5%",
      backgroundColor: "#1F1D2B",
      color: "#57c7d4",
    },
    "& li": {
      backgroundColor: "#1F1D2B",
      color: "#57c7d4",
    },
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));

const SettingTabPage = ({ item, deviceName, setItem }) => {
  const t = useTranslation();
  const positions = useSelector((state) =>
    Object.values(state.session.positions)
  );
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const PurchasedDevices = useSelector((state) =>
    Object.values(state.setting.PurchasedDevices)
  );
  const dispatch = useDispatch();
  const items = useSelector((state) => Object.values(state.devices.items));
  const found_device = items.find((element) => element.id == selectedDeviceId);
  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: null,
    title: null,
    type: "no",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });

  const [password, setPassword] = useState("");
  const [period, setPeriod] = useState("KMs");
  const [groupIds, setGroupIds] = useState([]);
  const [EntererPassword, setEntererPassword] = useState("");
  const [Mileage, setMileage] = useState(0);
  const [notificator, setNotificator] = useState([]);

  const Position = useSelector((state) => {
    if (state.devices.selectedId) {
      const position =
        state.session.positions[state.devices.selectedId] || null;
      return position;
    }
    return null;
  });

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const [loader, setLoader] = useState(false);
  const [ValueBuffer, setValueBuffer] = useState(Mileage);
  const progressRef = React.useRef(() => {});
  const [ShowMileageSaveButton, setShowMileageSaveButton] = useState(false);
  const [ShowHoursSaveButton, setShowHoursSaveButton] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ShowDeviceNameSaveButton, setShowDeviceNameSaveButton] =
    useState(false);

  const [state, setState] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [ShowSpeedingAlarmSaveButton, setShowSpeedingAlarmSaveButton] =
    useState(false);

  const [ShowTempAlarmSaveButton, setShowTempAlarmSaveButton] = useState();
  const [BufferIgnitionState, setBufferIgnitionState] = useState();

  const SleepMode = useSelector((state) => state.setting.SleepMode);
  const SpeedingAlarm = useSelector((state) => state.setting.SpeedingAlarm);
  const GroupList = useSelector((state) => state.setting.GroupList);
  const GroupId = useSelector((state) => state.setting.GroupId);
  const Ignition = useSelector((state) => state.setting.Ignition);
  const TempAlarm = useSelector((state) => state.setting.TempAlarm);
  const Attributes = useSelector((state) => state.setting.Attributes);
  const devices = useSelector((state) => Object.values(state.devices.items));

  const IsSleepModeVisible = useSelector(
    (state) => state.setting.IsSleepModeVisible
  );
  const IsSpeedingAlarmVisible = useSelector(
    (state) => state.setting.IsSpeedingAlarmVisible
  );
  const isSetIgnitionVisible = useSelector(
    (state) => state.setting.isSetIgnitionVisible
  );
  const isTempVisible = useSelector((state) => state.setting.isTempVisible);
  const isGroupVisible = useSelector((state) => state.setting.isGroupVisible);
  const hoursEnabled = useSelector((state) => state.setting.hoursEnabled);
  const isMileageVisible = useSelector(
    (state) => state.setting.isMileageVisible
  );

  const [isSaveIgnitionButtonVisible, setIsSaveIgnitionButtonVisible] =
    useState(false);
  const [periodMenuOpen, setPeriodMenuOpen] = useState(false);
  const [Hours, setHours] = useState(0);
  const [groupSaveButton, setGroupSaveButton] = useState(false);

  let deviceStatus;
  let IMEINumber;

  useEffectAsync(async () => {
    if (Position) {
      // alert("odometer -"+Position.attributes.odometer)
      if (
        Position.attributes.hours ||
        Position.attributes.hours != null ||
        Position.attributes.hours != undefined ||
        Position.attributes.hours > 0
      ) {
        setHours((Position.attributes.hours / (1000 * 60 * 60)).toFixed(0));
        setLoader(false);
      }

      if (
        Position.attributes.totalDistance ||
        Position.attributes.totalDistance != null ||
        Position.attributes.totalDistance != undefined ||
        Position.attributes.totalDistance > 0
      ) {
        setMileage((Position.attributes.totalDistance / 1000).toFixed(2));
        setLoader(false);
      }
    }

    var res = await fetch("/api/groups");
    if (res.ok) {
      var data = await res.json();
      dispatch(settingActions.UpdateGroupList(data));
    }

    var res = await fetch(`/api/devices/${selectedDeviceId}`);

    if (res.ok) {
      var data = await res.json();

      const attributes = data?.attributes;
      const accessConfiguration = attributes.accessConfiguration?.split(",");
      dispatch(settingActions.UpdateAttributes({ ...attributes }));
      // let accessConfiguration = "0,1,0,0,0,0,1,1,1,0,1,0".split(",");
      if (accessConfiguration && accessConfiguration.length > 0) {
        dispatch(
          settingActions.SetisSetIgnitionVisible(accessConfiguration[0] === "1")
        );
        dispatch(
          settingActions.SetIsSpeedingAlarmVisible(
            accessConfiguration[1] === "1"
          )
        );
        dispatch(
          settingActions.SetIsSleepModeVisible(accessConfiguration[2] === "1")
        );
        dispatch(
          settingActions.SetisTempVisible(accessConfiguration[3] === "1")
        );
        dispatch(
          settingActions.SetisTempMapVisible(accessConfiguration[9] === "1")
        );
        dispatch(
          settingActions.SetisGroupVisible(accessConfiguration[8] === "1")
        );
        dispatch(
          settingActions.SetHoursEnabled(accessConfiguration[10] === "1")
        );
        dispatch(
          settingActions.SetisMileageVisible(accessConfiguration[11] === "1")
        );
        dispatch(
          settingActions.SetisPreferenceVisible(accessConfiguration[12] === "1")
        );
        dispatch(
          settingActions.SetisCalendarVisible(accessConfiguration[13] === "1")
        );
        dispatch(
          settingActions.SetisAssignedGroupVisible(
            accessConfiguration[14] === "1"
          )
        );
      } else {
        dispatch(settingActions.SetisSetIgnitionVisible(false));
        dispatch(settingActions.SetIsSpeedingAlarmVisible(false));
        dispatch(settingActions.SetIsSleepModeVisible(false));
        dispatch(settingActions.SetisTempVisible(true));
        dispatch(settingActions.SetisTempMapVisible(false));
        dispatch(settingActions.SetisGroupVisible(false));
        dispatch(settingActions.UpdateDriverVisible(false));
        dispatch(settingActions.UpdateDriverBehaviourVisible(false));
        dispatch(settingActions.UpdateMaintenanceVisible(false));
        dispatch(settingActions.UpdateNotificationsVisible(false));
        dispatch(settingActions.UpdateGroupsVisible(false));
        dispatch(settingActions.SetHoursEnabled(false));
        dispatch(settingActions.SetisMileageVisible(false));
        dispatch(settingActions.SetisPreferenceVisible(false));
        dispatch(settingActions.SetisCalendarVisible(false));
        dispatch(settingActions.SetisAssignedGroupVisible(false));
      }

      const contact = data.contact?.split(",");
      if (contact && contact.length > 0) {
        dispatch(settingActions.UpdateIgnition(contact[0] === "1"));
        dispatch(settingActions.UpdateSleepMode(contact[2] !== "0"));
        dispatch(settingActions.UpdateSpeedingAlarm(contact[1]));
        dispatch(settingActions.UpdateTempAlarm(contact[3]));
      }

      dispatch(settingActions.UpdateGroupId(data?.groupId));

      let hasSpeedNotification = await fetch(
        `/api/notifications/?deviceId=${selectedDeviceId}`
      );
      let result = await hasSpeedNotification.json();
      let notification = _.find(result, { type: "deviceOverspeed" });

      if (!!notification) {
        let notificators = !!notification.notificators
          ? notification?.notificators?.split(",")
          : [];
        setNotificator([...notificators]);
      } else {
        setNotificator([]);
      }
    }
  }, [selectedDeviceId]);

  const ShowAlertFun = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 20000);
  };

  const GetDeviceStatus = (status, deviceId) => {
    const found_pos = positions.find((element) => element.deviceId == deviceId);
    if (found_pos) {
      if (found_pos.attributes.motion == true) {
        return "motion";
      }
      if (status == "online") {
        return "online";
      }
      if (status == "offline") {
        return "offline";
      }
      return "unknown";
    }
    return "offline";
  };

  const handleNotificator = (method) => {
    let notificatorTemp = notificator;
    let notificatorIndex = notificatorTemp.indexOf(method);
    if (notificatorIndex > -1) {
      notificatorTemp.splice(notificatorIndex, 1);
    } else {
      notificatorTemp.push(method);
    }

    setNotificator([...notificatorTemp]);
    if (notificatorTemp.length) {
      setShowSpeedingAlarmSaveButton(true);
    } else {
      setShowSpeedingAlarmSaveButton(false);
    }
  };
  const handleSave = async () => {
    setLoader(true);
    setShowDeviceNameSaveButton(false);
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
    let newItem = {
      ...item,
      id: selectedDeviceId,
      uniqueId: found_device.uniqueId,
      model: found_device.model,
      contact: found_device.contact,
      attributes: {
        ...found_device?.attributes,
        accessConfiguration: found_device?.attributes?.accessConfiguration,
      },
      category: found_device.category,
      groupId: found_device.groupId,
    };
    newItem = newItem.hasOwnProperty("name")
      ? newItem
      : { ...newItem, name: deviceName };
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newItem),
    };
    fetch(`/api/devices/${selectedDeviceId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setItem(newItem);
        setLoader(false);

        const responseAPI = fetch("/api/devices");
        if (responseAPI.ok) {
          setLoader(false);
          setShowDeviceNameSaveButton(false);
          dispatch(devicesActions.refresh(responseAPI.json()));
        }
      });
  };

  const handleMileageSave = (selectedRow, type = "mileage") => {
    if (type == "mileage") {
      setShowMileageSaveButton(false);
    } else {
      setShowHoursSaveButton(false);
    }

    deviceStatus = GetDeviceStatus(selectedRow.status, selectedRow.id);

    if (
      (!deviceStatus ||
        deviceStatus === "unknown" ||
        deviceStatus === "offline") &&
      !hoursEnabled
    ) {
      setDialog({
        isOpen: true,
        handleClose: () => setDialog({ isOpen: false }),
        handleSuccess,
        decription: "Cannot update at this time vehicle not online",
        title: "Stop",
        closeBtnText: "Close",
      });
    } else {
      const fun = async () => {
        setLoader(true);
        progressRef.current = () => {
          if (progress > 100) {
            setProgress(0);
            setBuffer(10);
          } else {
            const diff = Math.random() * 10;
            const diff2 = Math.random() * 10;
            setProgress(progress + diff);
            setBuffer(progress + diff + diff2);
          }
        };

        if (type == "mileage") {
          const commandResponse = await fetch("/api/commands/send", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              deviceId: selectedDeviceId,
              type: "custom",
              attributes: {
                data: `610,${(Mileage * 1000).toString()}`,
              },
            }),
          });
        }

        const response = await fetch(
          `/api/devices/${selectedDeviceId}/accumulators`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              deviceId: selectedDeviceId,
              totalDistance: Mileage ? Mileage * 1000 : 0,
              hours: Hours ? Hours * 1000 * 60 * 60 : 0,
            }),
          }
        );

        if (response.ok) {
          setLoader(false);
          setDialog({ isOpen: false });
          // SetSnackOpen();
        }
      };
      setDialog({
        isOpen: true,
        handleClose: () => setDialog({ isOpen: false }),
        handleDisagreeClose: () => {
          setDialog({ isOpen: false });
          setMileage(ValueBuffer);
        },
        handleSuccess: fun,
        decription: `If the ${
          type == "mileage" ? "mileage" : "hours"
        } is not set following installation then setting a new ${
          type == "mileage" ? "mileage" : "hours"
        } entry will only reflect future logs. Previous logs will show old ${
          type == "mileage" ? "mileage" : "hours"
        } `,
        title: "Stop",
        type: "conform",
        confmBtnText: "Agree",
        closeBtnText: "Disagree",
      });
    }
  };

  const handleClick = () => {
    if (!ShowMileageSaveButton) {
      setShowMileageSaveButton(true);
    }
  };

  const handleHoursClick = () => {
    if (!ShowHoursSaveButton) {
      setShowHoursSaveButton(true);
    }
  };

  const handelClickName = () => {
    if (!ShowDeviceNameSaveButton) {
      setShowDeviceNameSaveButton(true);
    }
  };

  const handleSuccess = () => {
    setDialog(false);
  };
  const selectedRow = items.filter((i) => i.id === selectedDeviceId)[0];
  const classes = useStyles();

  const refreshDeviceList = async () => {
    const response = await fetch("/api/devices");
    if (response.ok) {
      const devices = await response.json();
      dispatch(devicesActions.refresh(devices));
    }
  };

  const handleSleepmodeChange = async (event) => {
    if (Position && Position.attributes && Position.attributes.ignition) {
      const status = event.target.checked;
      const data = status ? "124,1" : "102,10,240";

      var res = await fetch(`/api/devices/${selectedDeviceId}`);

      const dataOfVehicle = await res.json();

      const contact = dataOfVehicle.contact.split(",");

      contact[2] = status ? "1" : "0";
      let newContact = "";
      contact.forEach((e, i) => {
        if (contact[i + 1]) {
          newContact = `${newContact + e},`;
        } else {
          newContact += e;
        }
      });

      var res = await fetch(`/api/devices/${selectedDeviceId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...dataOfVehicle, contact: newContact }),
      });

      if (res.ok) {
        const res = await fetch("/api/commands/send", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: selectedDeviceId,
            type: "custom",
            attributes: {
              data,
            },
          }),
        });

        if (res.ok) {
          dispatch(settingActions.UpdateSleepMode(status));
          // setSleepMode(status);
          dispatch(
            notificationActions.SetCustomAlertNotification(
              status ? "Sleep Mode Activated" : "Sleep Mode Deactivated"
            )
          );
        }
      }
    } else {
      setDialog({
        isOpen: true,
        handleClose: () => setDialog({ isOpen: false }),
        handleDisagreeClose: () => {
          setDialog({ isOpen: false });
        },
        handleSuccess: () => {},
        decription: "Ignition needs to be on to activate or deactivate",
        title: "Stop",
        type: "",
        closeBtnText: "Close",
      });
    }
  };

  const handleIgnitionChange = async (value) => {
    debugger;
    const status = !Ignition;
    const data = status ? "900,1,0" : "900,1,0,20";

    var res = await fetch(`/api/devices/${selectedDeviceId}`);

    const dataOfVehicle = await res.json();

    const contact = dataOfVehicle.contact.split(",");

    contact[0] = status ? "1" : "0";
    if (contact[4] === password.toString()) {
      setPassword("");
      let newContact = "";
      contact.forEach((e, i) => {
        if (contact[i + 1]) {
          newContact = `${newContact + e},`;
        } else {
          newContact += e;
        }
      });

      var res = await fetch(`/api/devices/${selectedDeviceId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...dataOfVehicle, contact: newContact }),
      });

      if (res.ok) {
        const res = await fetch("/api/commands/send", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: selectedDeviceId,
            type: "custom",
            attributes: {
              data,
            },
          }),
        });

        if (res.ok) {
          setIsSaveIgnitionButtonVisible(false);
          dispatch(notificationActions.updateIgnitionState(status));
          // setIgnition(status);
          dispatch(settingActions.UpdateIgnition(status));
          // alert(status?"Ignition Enabled":"Igniton Disabled")
          dispatch(
            notificationActions.SetCustomAlertNotification(
              status ? "Ignition Enabled" : "Igniton Disabled"
            )
          );
        }
      }
    } else {
      setPassword("");
      setIsSaveIgnitionButtonVisible(false);

      dispatch(
        notificationActions.SetCustomAlertNotification("Incorrect Password")
      );

      // alert("Incorrect Password");
    }
  };

  const handlePermissionLink = (deviceId, notificationId) => {
    if (deviceId && notificationId) {
      fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId,
          notificationId,
        }),
      }).then((res) => {
        if (res.ok) {
          //do nothing
        }
      });
    }
  };

  const handleSpeedingAlarmRemove = async () => {
    let res = await fetch(`/api/devices/${selectedDeviceId}`);

    let dataOfVehicle = await res.json();
    let attributes = dataOfVehicle.attributes;
    let contact = dataOfVehicle.contact;
    let contactArr = contact.split(",");
    contactArr[1] = 0;
    contact = contactArr.join(",");

    delete attributes.speedLimit;
    var deviceRes = await fetch(`/api/devices/${selectedDeviceId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...dataOfVehicle,
        contact: contact,
        attributes: attributes,
      }),
    });

    let hasSpeedNotification = await fetch(
      `/api/notifications/?deviceId=${selectedDeviceId}`
    );
    let result = await hasSpeedNotification.json();
    let notifications = _.filter(result, { type: "deviceOverspeed" });
    dispatch(settingActions.UpdateSpeedingAlarm(0));

    if (!!notifications && notifications.length) {
      notifications.forEach((notification) => {
        fetch(
          `/api/notifications/` +
            notification?.id +
            `?_dc=${new Date().getTime()}`,
          { method: "DELETE" }
        );
      });
    }
  };

  const handleSpeedingAlarmSave = async () => {
    var res = await fetch(`/api/devices/${selectedDeviceId}`);

    const dataOfVehicle = await res.json();
    const attributes = dataOfVehicle.attributes;
    const contact = dataOfVehicle.contact.split(",");
    const speedLimit = speedToKnots(Number(SpeedingAlarm), "kmh");

    contact[1] = SpeedingAlarm;
    let newContact = "";
    contact.forEach((e, i) => {
      if (contact[i + 1]) {
        newContact = `${newContact + e},`;
      } else {
        newContact += e;
      }
    });

    var res = await fetch(`/api/devices/${selectedDeviceId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...dataOfVehicle,
        contact: newContact,
        attributes: {
          ...attributes,
          speedLimit: speedLimit,
        },
      }),
    });

    let hasSpeedNotification = await fetch(
      `/api/notifications/?deviceId=${selectedDeviceId}`
    );
    let result = await hasSpeedNotification.json();
    let notification = _.find(result, { type: "deviceOverspeed" });

    if (res.ok) {
      if (!!!notification) {
        fetch("/api/notifications", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: 0,
            type: "deviceOverspeed",
            always: true,
            notificators: notificator.join(","),
            calendarId: 0,
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            // ResetForm();
            handlePermissionLink(selectedDeviceId, result?.id);
          });
      } else {
        fetch("/api/notifications/" + notification?.id, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: notification?.id,
            type: "deviceOverspeed",
            always: true,
            notificators: notificator.join(","),
            calendarId: 0,
          }),
        });
      }
    }

    if (res.ok) {
      const response = await fetch("/api/commands/send", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId: selectedDeviceId,
          type: "custom",
          attributes: {
            data: `123,${SpeedingAlarm.toString()}`,
          },
        }),
      });

      dispatch(notificationActions.updateSpeedingAlarmState(SpeedingAlarm));
      setShowSpeedingAlarmSaveButton(false);
    }
  };

  const handleTempratureAlarmSave = async () => {
    var res = await fetch(`/api/devices/${selectedDeviceId}`);

    const dataOfVehicle = await res.json();

    const contact = dataOfVehicle.contact.split(",");

    contact[3] = TempAlarm;
    let newContact = "";
    contact.forEach((e, i) => {
      if (contact[i + 1]) {
        newContact = `${newContact + e},`;
      } else {
        newContact += e;
      }
    });

    var res = await fetch(`/api/devices/${selectedDeviceId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...dataOfVehicle, contact: newContact }),
    });

    if (res.ok) {
      const response = await fetch("/api/commands/send", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId: selectedDeviceId,
          type: "custom",
          attributes: {
            data: `136,1,${TempAlarm.toString()}`,
          },
        }),
      });

      if (response.ok) {
        // dispatch(notificationActions.updateSpeedingAlarmState(SpeedingAlarm));
        setShowTempAlarmSaveButton(false);
      }
    }
  };

  const handleGroupListSave = async () => {
    const res = await fetch(`/api/devices/${selectedDeviceId}`);
    const dataOfVehicle = await res.json();
    let devicesTemp = JSON.stringify(devices);
    devicesTemp = JSON.parse(devicesTemp);
    _.find(devicesTemp, { id: selectedDeviceId }).groupId = GroupId
      ? Number(GroupId)
      : "";
    dispatch(devicesActions.update(devicesTemp));

    const resp = fetch(`/api/devices/${selectedDeviceId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...dataOfVehicle,
        groupId: GroupId ? Number(GroupId) : "",
      }),
    });

    if (res.ok) {
      setGroupSaveButton(false);
      refreshDeviceList();
    }
  };

  const handleCheckboxClick = (rowData) => {
    if (!rowData.tableData.checked) {
      const resp = fetch(`/api/devices/${rowData.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: rowData.id,
          name: rowData.name,
          deviceId: selectedDeviceId,
        }),
      });
    } else {
      const resp = fetch(`/api/devices/${rowData.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: rowData.id,
          name: rowData.name,
          deviceId: "",
        }),
      });

      // Link COde
    }
    // rowData.tableData.checked = true
  };

  return (
    <div className="page-scrll">
      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      {loader ? (
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
        />
      ) : (
        ""
      )}
      <Grid container spacing={{ xs: 3, sm: 2, md: 2, lg: 3, xl: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <Card className={classes.paperRoot}>
            <CardHeader
              // avatar={handleStatus(selectedRow.status, selectedRow.id)}
              avatar={<EditOutlinedIcon color="primary" />}
              // avatar={<DriveFileRenameOutlineOutlinedIcon/>}
              // action={
              // //   <IconButton aria-label="settings">
              // //     <MoreVertIcon />
              // //   </IconButton>
              // }
              title="Set Vehicle Name"
            />
            Click in text box to name the vehicle, then select save
            <TextField
              style={{ width: "100%" }}
              margin="normal"
              value={
                item ? convertUnicode(item.name) : convertUnicode(deviceName)
              }
              onChange={(event) =>
                setItem({ ...item, name: event.target.value })
              }
              label="Vehicle Name"
              variant="filled"
              onClick={handelClickName}
            />
            {ShowDeviceNameSaveButton ? (
              <div className={classes.btn}>
                <ButtonGroup color="primary" orientation="horizontal">
                  <Button onClick={handleSave}>Save</Button>
                </ButtonGroup>
                <ButtonGroup
                  onClick={(e) => setShowDeviceNameSaveButton(false)}
                  color="secondary"
                  orientation="horizontal"
                >
                  <Button>Cancel</Button>
                </ButtonGroup>
              </div>
            ) : (
              ""
            )}
          </Card>
        </Grid>
        {hoursEnabled && false && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Card className={classes.paperRoot}>
              <CardHeader
                // avatar={handleStatus(selectedRow.status, selectedRow.id)}
                avatar={<DriveEtaOutlinedIcon color="primary" />}
                // action={
                // //   <IconButton aria-label="settings">
                // //     <MoreVertIcon />
                // //   </IconButton>
                // }
                title="Set Hours"
              />
              The current hours shows below. Click in the text box to update
              <>
                <TextField
                  style={{ width: "100%" }}
                  margin="normal"
                  value={Hours}
                  onClick={handleHoursClick}
                  onChange={(event) => setHours(event.target.value)}
                  label="Enter Hours"
                  variant="filled"
                />
              </>
              {ShowHoursSaveButton ? (
                <div className={classes.btn}>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="primary"
                      onClick={(e) => handleMileageSave(selectedRow, "hours")}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        setHours(ValueBuffer);
                        setShowHoursSaveButton(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              ) : (
                ""
              )}
            </Card>
          </Grid>
        )}
        {isMileageVisible && false && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Card className={classes.paperRoot}>
              <CardHeader
                // avatar={handleStatus(selectedRow.status, selectedRow.id)}
                avatar={<DriveEtaOutlinedIcon color="primary" />}
                // action={
                // //   <IconButton aria-label="settings">
                // //     <MoreVertIcon />
                // //   </IconButton>
                // }
                title="Set Mileage"
              />
              The current mileage shows below. Click in the text box to update
              <>
                <TextField
                  style={{ width: "100%" }}
                  margin="normal"
                  value={Mileage}
                  onClick={handleClick}
                  onChange={(event) => setMileage(event.target.value)}
                  label="Enter Mileage (km)"
                  variant="filled"
                />
              </>
              {ShowMileageSaveButton ? (
                <div className={classes.btn}>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="primary"
                      onClick={(e) => handleMileageSave(selectedRow, "mileage")}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        setMileage(ValueBuffer);
                        setShowMileageSaveButton(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              ) : (
                ""
              )}
            </Card>
          </Grid>
        )}

        {IsSleepModeVisible && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Card className={classes.paperRoot}>
              <CardHeader
                // avatar={handleStatus(selectedRow.status, selectedRow.id)}
                avatar={<HotelOutlinedIcon color="primary" />}
                title="Set Sleep Mode"
              />
              Sleep mode powers off the GPS module and puts the GSM module to
              sleep therefore, conserving battery drainage. Vibration or
              starting the vehicle will resume normal operation
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={SleepMode}
                      onChange={handleSleepmodeChange}
                      name="SleepMode"
                      color="primary"
                    />
                  }
                  label={SleepMode ? "Sleep Mode - On" : "Sleep Mode - Off"}
                />
              </FormGroup>
            </Card>
          </Grid>
        )}
        {isSetIgnitionVisible && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Card className={classes.paperRoot}>
              <CardHeader
                // avatar={handleStatus(selectedRow.status, selectedRow.id)}
                avatar={<VpnKeyOutlinedIcon color="primary" />}
                title="Set Ignition"
              />
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Ignition}
                      // onChange={handleIgnitionChange}
                      name="Ignition"
                      color="primary"
                    />
                  }
                  label={Ignition ? "Ignition Enabled" : "Igniton Disabled "}
                />
              </FormGroup>
              This function will disable or enable the vehicle's ignition
              remotely. To disable the Ignition the vehicle must be traveling
              below 15 km's per hour.
              <br />
              <TextField
                margin="normal"
                type="password"
                value={password}
                style={{ width: "100%" }}
                onChange={(event) => {
                  setIsSaveIgnitionButtonVisible(true);
                  if (event.target.value > 9999) {
                    return;
                  }
                  setPassword(event.target.value);
                }}
                onBlur={password}
                label="Please Click to enter password"
                variant="filled"
              />
              {isSaveIgnitionButtonVisible && (
                <div className={classes.btn}>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="primary"
                      onClick={(e) => {
                        handleIgnitionChange();
                      }}
                    >
                      Set
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        setIsSaveIgnitionButtonVisible(false);
                        setPassword("");
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              )}
            </Card>
          </Grid>
        )}

        {IsSpeedingAlarmVisible && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Card className={classes.paperRoot}>
              <CardHeader
                // avatar={handleStatus(selectedRow.status, selectedRow.id)}
                avatar={<AlarmAddOutlined color="primary" />}
                title={
                  item
                    ? `${convertUnicode(item.name)} Speed Alarm`
                    : `${convertUnicode(deviceName)} Speed Alarm`
                }
              />
              Select to receive a notification when speed exceeds setting
              <FormGroup row>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>Select Speeding Alarm</InputLabel>

                  <Select
                    value={SpeedingAlarm}
                    onChange={(event) => {
                      // setSpeedingAlarm(event.target.value);
                      dispatch(
                        settingActions.UpdateSpeedingAlarm(event.target.value)
                      );

                      if (notificator.length) {
                        setShowSpeedingAlarmSaveButton(true);
                      }
                    }}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  >
                    <MenuItem value="70"> 70 km/h</MenuItem>
                    <MenuItem value="75"> 75 km/h</MenuItem>
                    <MenuItem value="80"> 80 km/h</MenuItem>
                    <MenuItem value="85"> 85 km/h</MenuItem>
                    <MenuItem value="90"> 90 km/h</MenuItem>
                    <MenuItem value="95"> 95 km/h</MenuItem>
                    <MenuItem value="100"> 100 km/h</MenuItem>
                    <MenuItem value="105"> 105 km/h</MenuItem>
                    <MenuItem value="110"> 110 km/h</MenuItem>
                    <MenuItem value="115"> 115 km/h</MenuItem>
                    <MenuItem value="120"> 120 km/h</MenuItem>
                    <MenuItem value="125"> 125 km/h</MenuItem>
                    <MenuItem value="130"> 130 km/h</MenuItem>
                    <MenuItem value="135"> 135 km/h</MenuItem>
                    <MenuItem value="140"> 140 km/h</MenuItem>
                    <MenuItem value="145"> 145 km/h</MenuItem>
                    <MenuItem value="150"> 150 km/h</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
              {SpeedingAlarm != 0 ? (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificator.includes("mail")}
                          onChange={(e) => {
                            handleNotificator("mail");
                          }}
                        />
                      }
                      label="Mail"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificator.includes("sms")}
                          onChange={(e) => {
                            handleNotificator("sms");
                          }}
                        />
                      }
                      label="SMS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notificator.includes("web")}
                          onChange={(e) => {
                            handleNotificator("web");
                          }}
                        />
                      }
                      label="Web"
                    />
                  </FormGroup>
                </>
              ) : null}
              {ShowSpeedingAlarmSaveButton ? (
                <div className={classes.btn}>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="primary"
                      onClick={(e) => handleSpeedingAlarmSave()}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        setShowSpeedingAlarmSaveButton(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              ) : (
                ""
              )}
              {!ShowSpeedingAlarmSaveButton && (
                <>
                  {SpeedingAlarm != 0 ? (
                    <div className={classes.btn}>
                      <ButtonGroup orientation="horizontal">
                        <Button
                          color="primary"
                          onClick={(e) => handleSpeedingAlarmRemove()}
                        >
                          Remove Speed Warning
                        </Button>
                      </ButtonGroup>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Card>
          </Grid>
        )}

        {isTempVisible && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Card className={classes.paperRoot}>
              <CardHeader
                // avatar={handleStatus(selectedRow.status, selectedRow.id)}
                avatar={<AcUnitOutlinedIcon color="primary" />}
                title="Set temperature level to receive an alert"
              />
              temperature alarm description
              <FormGroup row>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel> Select Temp </InputLabel>

                  <Select
                    value={TempAlarm}
                    onChange={(event) => {
                      //                  setTempAlarm();
                      dispatch(
                        settingActions.UpdateTempAlarm(event.target.value)
                      );
                      setShowTempAlarmSaveButton(true);
                    }}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="-1">-1</MenuItem>
                    <MenuItem value="-2">-2</MenuItem>
                    <MenuItem value="-3">-3</MenuItem>
                    <MenuItem value="-4">-4</MenuItem>
                    <MenuItem value="-5">-5</MenuItem>
                    <MenuItem value="-6">-6</MenuItem>
                    <MenuItem value="-7">-7</MenuItem>
                    <MenuItem value="-8">-8</MenuItem>
                    <MenuItem value="-9">-9</MenuItem>
                    <MenuItem value="-10">-10</MenuItem>
                    <MenuItem value="-11">-11</MenuItem>
                    <MenuItem value="-12">-12</MenuItem>
                    <MenuItem value="-13">-13</MenuItem>
                    <MenuItem value="-14">-14</MenuItem>
                    <MenuItem value="-15">-15</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
              {ShowTempAlarmSaveButton ? (
                <div className={classes.btn}>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="primary"
                      onClick={(e) => handleTempratureAlarmSave()}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        setShowTempAlarmSaveButton(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              ) : (
                ""
              )}
            </Card>
          </Grid>
        )}

        {isGroupVisible && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Card className={classes.paperRoot}>
              <CardHeader
                // avatar={handleStatus(selectedRow.status, selectedRow.id)}
                avatar={<GroupIcon color="primary" />}
                title="Set Groups"
              />
              Click to associate vehicle to multiple group
              <FormGroup row>
                <FormControl variant="filled" margin="normal" fullWidth>
                  <InputLabel>Select Group</InputLabel>

                  <Select
                    value={GroupId}
                    onChange={(event) => {
                      dispatch(
                        settingActions.UpdateGroupId(event.target.value)
                      );
                      setGroupSaveButton(true);
                    }}
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  >
                    {GroupList.map((rowData, index) => (
                      <MenuItem
                        key={Number(rowData.id)}
                        value={Number(rowData.id)}
                      >
                        <ListItemText primary={rowData?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="filled" margin="normal" fullWidth />
              </FormGroup>
              {groupSaveButton ? (
                <div className={classes.btn}>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="primary"
                      onClick={(e) => handleGroupListSave()}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup orientation="horizontal">
                    <Button
                      color="secondary"
                      onClick={(e) => {
                        setGroupSaveButton(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </div>
              ) : (
                ""
              )}
            </Card>
          </Grid>
        )}
      </Grid>

      {/* <CustomAlertBox showAlert={showAlert}/> */}
    </div>
  );
};

const ChipsSelectView = ({ selected }) => {
  const classes = useStyles();

  return (
    <div className={classes.chips}>
      {selected.length > 0 &&
        selected.map(
          (d) =>
            d && <Chip key={d.id} label={d.name} className={classes.chip} />
        )}
    </div>
  );
};

export default SettingTabPage;
