import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { devicesActions } from "store";
import { useEffectAsync } from "reactHelper";
import DeviceRow from "./DeviceRow";

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: "100%",
  },
  listInner: {
    position: "relative",
    margin: theme.spacing(1.5, 0),
  },
}));

const DeviceList = ({ devices }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listInnerEl = useRef(null);
  const listRef = useRef();

  const [expandedItems, setExpandedItems] = useState({});
  const getItemSize = (index) => (expandedItems[index] ? 110 : 70);

  if (listInnerEl.current) {
    listInnerEl.current.className = classes.listInner;
  }

  // Toggle item size
  const toggleSize = (index) => {
    setExpandedItems((prev) => {
      const updated = { ...prev, [index]: true };
      listRef.current.resetAfterIndex(index, true);
      return updated;
    });
  };

  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffectAsync(async () => {
    const response = await fetch("/api/devices");
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, []);

  return (
    <AutoSizer className={classes.list}>
      {({ height, width }) => (
        <VariableSizeList
          width={width}
          height={height}
          itemCount={devices.length}
          itemData={{ devices, toggleSize, expandedItems }}
          itemSize={(index) => (expandedItems[index] ? 100 : 70)}
          overscanCount={10}
          ref={listRef}
          innerRef={listInnerEl}
        >
          {DeviceRow}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
};

export default DeviceList;
