import React, { useState, useEffect } from "react";
import MainToolbar from "components/MainToolbar";
import makeStyles from "@mui/styles/makeStyles";
import {
  AddCircle,
  Edit,
  Save,
  Delete,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
} from "@mui/icons-material";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Paper,
  FormControl,
  TextField,
  Checkbox,
  Chip,
  ListItemText,
  Input,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DialogBox, convertUnicode, useEffectAsync } from "reactHelper";
import EditCollectionView from "EditCollectionView";
import { useAttributePreference } from "common/util/preferences";
import { useTranslation } from "common/components/LocalizationProvider";
import usePositionAttributes from "common/attributes/usePositionAttributes";
import {
  formatDistance,
  formatSpeed,
  numberDistanceToStringCommaSeprated,
} from "common/util/formatter";
import _ from "lodash";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
      display: "none",
    },
  },
  textfieldClass: {
    "& .MuiInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  columnAction: {
    // width: theme.spacing(1),
    // padding: theme.spacing(0, 1),
  },
  btn: {
    display: "flex",
    "& div": {
      margin: "5px",
    },
  },
}));

const ViewButton = ({ cb }) => {
  const edit = (e) => {
    e.stopPropagation();
    cb(e);
  };
  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <Button onClick={(e) => edit(e)} variant="outlined" color="primary">
        View
      </Button>
    </div>
  );
};

const EditView = ({ cb }) => {
  const edit = (e) => {
    e.stopPropagation();
    cb(e);
  };
  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <EditOutlinedIcon onClick={(e) => edit(e)} />
    </div>
  );
};

const DeleteView = ({ cb }) => {
  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: "Description",
    title: "titile",
    type: "conform",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });
  const deleteRow = (e) => {
    const fun = async () => {
      setDialog({ isOpen: false });
      await cb(e);
    };

    e.stopPropagation();
    setDialog({
      isOpen: true,
      handleClose: () => setDialog({ isOpen: false }),
      handleDisagreeClose: () => {
        setDialog({ isOpen: false });
        // setMileage(ValueBuffer);
      },
      handleSuccess: fun,
      decription: "Are you sure you want to delete this record  ?",
      title: "Alert !!",
      type: "conform",
      confmBtnText: "Yes  ",
      closeBtnText: "No ",
    });
    // setDialog({...Dialog,handleSuccess:() => {cb(e)}})
  };

  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      <DeleteOutlineOutlinedIcon onClick={(e) => deleteRow(e)} />
    </div>
  );
};

const MaintenancesView = ({ updateTimestamp, onMenuClick }) => {
  const t = useTranslation();
  const positionAttributes = usePositionAttributes(t);
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [randomKey, setRandomKey] = useState(0);
  const [SelectedRow, setSelectedRow] = useState(5);
  const [ShowAddMaintenanceContainer, setShowAddMaintenanceContainer] =
    useState(false);
  const speedUnit = useAttributePreference("speedUnit");
  const distanceUnit = useAttributePreference("distanceUnit");
  const [IsEdit, setIsEdit] = useState(false);
  const [notificationId, setNotificationId] = useState(null);

  const userId = useSelector(
    (state) => state.session.user && state.session.user.id
  );
  const Position = useSelector((state) => {
    if (state.devices.selectedId) {
      const position =
        state.session.positions[state.devices.selectedId] || null;
      return position;
    }
    return null;
  });

  const devices = useSelector((state) => Object.values(state.devices.items));
  const positions = useSelector((state) =>
    Object.values(state.session.positions)
  );
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  //  console.log('selectedDeviceId--', selectedDeviceId);
  const [deviceId, setDeviceId] = useState(selectedDeviceId);
  const [triggerEdit, setTriggerEdit] = useState(false);
  const [isSelectorOpen, setisSelectorOpen] = useState(false);
  const [selectedDevicePosition, setSelectedDevicePosition] =
    useState(Position);
  const [AddNewObject, setAddNewObject] = useState({
    id: 0,
    name: null,
    type: "odometer",
    start: null,
    period: null,
    attributes: {},
  });

  const [NotificationObject, setNotificationObject] = useState({
    id: -1,
    always: false,
    type: "maintenance",
    calendarId: 0,
    attributes: {},
    notificators: [],
  });

  useEffect(() => {
    if (triggerEdit && AddNewObject.id != 0) {
      EditMaintenances();
    }
  }, [triggerEdit, AddNewObject]);
  // useEffectAsync(async () => {
  //   const response = await fetch('/api/maintenance?userId='+userId);
  //   if (response.ok) {
  //     setItems(await response.json());
  //   }
  // }, [updateTimestamp]);

  useEffect(() => {
    if (deviceId) {
      if (!Position) {
        const devicePosition = _.find(positions, { deviceId });
        setSelectedDevicePosition(devicePosition);
      } else {
        setSelectedDevicePosition(Position);
      }
    } else {
      setSelectedDevicePosition(Position);
    }
  }, [positions, deviceId]);

  const convertAttribute = (key, value) => {
    const attribute = positionAttributes[key];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case "speed":
          return formatSpeed(value, speedUnit);
        case "distance":
          return formatDistance(value, distanceUnit);
        default:
          return value;
      }
    }

    return value;
  };

  useEffectAsync(async () => {
    if (deviceId) {
      const res = await fetch(`/api/notifications?deviceId=${deviceId}`, {
        method: "GET",
      });

      if (res.ok) {
        const data = await res.json();
        console.log(data);
        let maintentanceRequiredNotification = _.orderBy(
          _.filter(data, { type: "maintenance" }),
          ["id"],
          ["desc"]
        );

        let maintenanceRequiredOutput = _.first(
          maintentanceRequiredNotification
        );

        if (!!maintenanceRequiredOutput) {
          if (!!maintenanceRequiredOutput?.notificators) {
            let NotificationObjectData = { ...NotificationObject };
            NotificationObjectData.notificators =
              maintenanceRequiredOutput?.notificators;
            setNotificationId(maintenanceRequiredOutput?.id);
            setNotificationObject(NotificationObjectData);
          }
        }
      }
    }
  }, [deviceId]);

  const EditMaintenances = async () => {
    const obj = { ...AddNewObject };

    if (obj.type === "hours") {
      obj.start = obj.start;
      obj.period =
        (obj.attributes.period - obj.attributes.notify) * 3600 * 1000;
    } else {
      obj.period = (obj.attributes.period - obj.attributes.notify) * 1000;
    }

    const response = await fetch(`/api/maintenance/${AddNewObject.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    });

    HandleMaintainenceNotification();
    // debugger;
    if (response.ok) {
      formReset();

      handleClick();
      // var arr = [];
      // setItems(arr.push(await response.json()));
    }
  };

  const formReset = () => {
    setIsEdit(false);
    setSelectedRow(null);
    setShowAddMaintenanceContainer(false);
    setTriggerEdit(false);
    setAddNewObject({
      id: 0,
      name: null,
      type: "odometer",
      period: null,
      start:
        selectedDevicePosition &&
        selectedDevicePosition?.attributes?.totalDistance
          ? Number(selectedDevicePosition?.attributes?.totalDistance)
          : 0,
      attributes: { period: 0, notify: 0 },
    });
  };

  const AddMaintenances = async () => {
    // debugger
    const obj = { ...AddNewObject };

    if (obj.type === "hours") {
      obj.start = obj.start;
      obj.period =
        (obj.attributes.period - obj.attributes.notify) * 3600 * 1000;
    } else {
      obj.period = (obj.attributes.period - obj.attributes.notify) * 1000;
    }
    const response = await fetch("/api/maintenance", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    });
    // debugger;
    if (response.ok) {
      const resp = await response.json();

      await fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId,
          maintenanceId: resp.id,
        }),
      });

      HandleMaintainenceNotification();

      await fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId,
          maintenanceId: resp.id,
        }),
      });
      formReset();
      handleClick();
      // var arr = [];
      // setItems(arr.push(await response.json()));
    }
  };

  const HandleMaintainenceNotification = async () => {
    const NotificationObjectData = { ...NotificationObject };
    NotificationObjectData.notificators = Array.isArray(
      NotificationObjectData.notificators
    )
      ? NotificationObjectData.notificators.join(",")
      : NotificationObjectData.notificators;

    if (notificationId) {
      NotificationObjectData.id = notificationId;
      const notificationResponse = await fetch(
        `/api/notifications/${notificationId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(NotificationObjectData),
        }
      );

      if (notificationResponse.ok) {
        const notificationResp = await notificationResponse.json();
        await fetch("/api/permissions", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId,
            notificationId: notificationResp?.id,
          }),
        });
      }
    } else {
      const notificationResponse = await fetch("/api/notifications", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(NotificationObjectData),
      });

      if (notificationResponse.ok) {
        const notificationResp = await notificationResponse.json();
        await fetch("/api/permissions", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId,
            notificationId: notificationResp?.id,
          }),
        });
      }
    }
  };

  const handleNotificationChange = (event) => {
    let notifiers = Array.isArray(NotificationObject.notificators)
      ? NotificationObject.notificators
      : NotificationObject.notificators.split(",");
    notifiers = notifiers.filter((item) => item);

    if (event.target.checked) {
      notifiers.push(event.target.name);
    } else {
      const index = notifiers.indexOf(event.target.name);
      if (index > -1) {
        notifiers.splice(index, 1);
      }
    }
    const NotificationObjectData = { ...NotificationObject };
    NotificationObjectData.notificators = notifiers;
    setNotificationObject({ ...NotificationObjectData });
  };
  const handleClick = async (mail, json) => {
    const deviceItems =
      deviceId !== "all" ? _.filter(devices, { id: deviceId }) : devices;
    let maintenanceItems = [];

    deviceItems.forEach(async (item, index) => {
      const maintenanceApiUrl = `/api/maintenance?userId=${userId}&deviceId=${item.id}`;
      const response = await fetch(maintenanceApiUrl);
      const deviceName = item.name;
      let newArr = [];
      if (response.ok) {
        newArr = _.map(await response.json(), (o) =>
          _.extend({ vehicleName: deviceName }, o)
        );
      }

      maintenanceItems = [...maintenanceItems, ...newArr];
      setItems(
        _.orderBy(
          maintenanceItems,
          [(maintenanceItem) => maintenanceItem.vehicleName.toLowerCase()],
          ["asc"]
        )
      );
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} lg={2}>
        <Paper className={classes.form}>
          {deviceId && deviceId != "" && deviceId != "all" ? (
            <>
              {ShowAddMaintenanceContainer ? (
                <Button
                  color="secondary"
                  onClick={() => {
                    setSelectedRow(null);
                    formReset();
                    setShowAddMaintenanceContainer(false);
                  }}
                >
                  Close
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircle />}
                  onClick={() => {
                    setSelectedRow(null);
                    formReset();
                    setShowAddMaintenanceContainer(true);
                  }}
                >
                  {IsEdit ? "Edit" : "Add New"}
                </Button>
              )}
            </>
          ) : (
            ""
          )}

          <FormControl variant="filled" margin="normal" fullWidth>
            <InputLabel>Vehicle</InputLabel>

            <Select
              value={deviceId || ""}
              onChange={(e) => {
                setDeviceId(e.target.value);
                formReset();
                setShowAddMaintenanceContainer(false);
              }}
              MenuProps={{ classes: { paper: classes.dropdownStyle } }}
            >
              <MenuItem value="all">All</MenuItem>
              {_.orderBy(
                devices,
                [(item) => item.name.toLowerCase()],
                ["asc"]
              ).map((device, index) => (
                <MenuItem key={index} value={device.id}>
                  {convertUnicode(device.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <ButtonGroup
              color="primary"
              orientation="vertical"
              disabled={!deviceId}
            >
              <Button onClick={() => handleClick(false, true)}>
                {t("reportShow")}
              </Button>
            </ButtonGroup>
          </FormControl>
        </Paper>
        <br />
        {ShowAddMaintenanceContainer && (
          <Paper className={classes.form}>
            <TextField
              margin="normal"
              type="text"
              onChange={(event) =>
                setAddNewObject({
                  ...AddNewObject,
                  name: event.target.value,
                })
              }
              label="Enter Name"
              value={AddNewObject.name}
              variant="filled"
              fullWidth
            />

            <FormControl variant="filled" margin="normal" fullWidth>
              <InputLabel>Select Period</InputLabel>

              <Select
                value={AddNewObject.type}
                onChange={(event) =>
                  setAddNewObject({
                    ...AddNewObject,
                    type: event.target.value,
                    start:
                      event.target.value == "odometer"
                        ? selectedDevicePosition.attributes.totalDistance
                        : selectedDevicePosition.attributes.hours,
                  })
                }
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                <MenuItem value="odometer">odometer</MenuItem>
                <MenuItem value="hours">hours</MenuItem>
              </Select>
            </FormControl>

            {AddNewObject.type === "odometer" ? (
              <TextField
                margin="normal"
                className={classes.textfieldClass}
                type="number"
                value={Number.parseFloat(AddNewObject.start * 0.001).toFixed(2)}
                // value={formatDistance(AddNewObject.start,distanceUnit)}
                inputProps={{ readOnly: true }}
                label="Start"
                variant="filled"
                fullWidth
              />
            ) : (
              <TextField
                margin="normal"
                className={classes.textfieldClass}
                type="text"
                value={Number.parseFloat(
                  Math.round(AddNewObject.start) / 1000 / 60 / 60
                ).toFixed(2)}
                onChange={(event) =>
                  setAddNewObject({
                    ...AddNewObject,
                    start: event.target.value,
                  })
                }
                label="Start"
                inputProps={{ readOnly: true }}
                variant="filled"
                fullWidth
              />
            )}

            {AddNewObject.type === "odometer" ? (
              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>Select Period</InputLabel>

                <Select
                  value={AddNewObject?.attributes?.period * 1000}
                  onChange={(event) =>
                    setAddNewObject({
                      ...AddNewObject,
                      attributes: {
                        ...AddNewObject?.attributes,
                        period: event.target.value / 1000,
                      },
                    })
                  }
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem value="500000">500 KM</MenuItem>
                  <MenuItem value="1000000">1,000 KM</MenuItem>
                  <MenuItem value="1500000">1,500 KM</MenuItem>
                  <MenuItem value="2000000">2,000 KM</MenuItem>
                  <MenuItem value="2500000">2,500 KM</MenuItem>
                  <MenuItem value="3000000">3,000 KM</MenuItem>
                  <MenuItem value="3500000">3,500 KM</MenuItem>
                  <MenuItem value="4000000">4,000 KM</MenuItem>
                  <MenuItem value="4500000">4,500 KM</MenuItem>
                  <MenuItem value="5000000">5,000 KM</MenuItem>
                  <MenuItem value="5500000">5,500 KM</MenuItem>
                  <MenuItem value="6000000">6000 KM</MenuItem>
                  <MenuItem value="6500000">6500 KM</MenuItem>
                  <MenuItem value="7000000">7000 KM</MenuItem>
                  <MenuItem value="7500000">7500 KM</MenuItem>
                  <MenuItem value="8000000">8000 KM</MenuItem>
                  <MenuItem value="8500000">8500 KM</MenuItem>
                  <MenuItem value="9000000">9000 KM</MenuItem>
                  <MenuItem value="9500000">9500 KM</MenuItem>
                  <MenuItem value="10000000">10,000 KM</MenuItem>
                  <MenuItem value="10500000">10,500 KM</MenuItem>
                  <MenuItem value="11000000">11,000 KM</MenuItem>
                  <MenuItem value="11500000">11,500 KM</MenuItem>
                  <MenuItem value="12000000">12,000 KM</MenuItem>
                  <MenuItem value="12500000">12,500 KM</MenuItem>
                  <MenuItem value="13000000">13,000 KM</MenuItem>
                  <MenuItem value="13500000">13,500 KM</MenuItem>
                  <MenuItem value="14000000">14,000 KM</MenuItem>
                  <MenuItem value="14500000">14,500 KM</MenuItem>
                  <MenuItem value="15000000">15,000 KM</MenuItem>
                  <MenuItem value="15500000">15,500 KM</MenuItem>
                  <MenuItem value="16000000">16,000 KM</MenuItem>
                  <MenuItem value="16500000">16,500 KM</MenuItem>
                  <MenuItem value="17000000">17,000 KM</MenuItem>
                  <MenuItem value="17500000">17,500 KM</MenuItem>
                  <MenuItem value="18000000">18,000 KM</MenuItem>
                  <MenuItem value="18500000">18,500 KM</MenuItem>
                  <MenuItem value="19000000">19,000 KM</MenuItem>
                  <MenuItem value="19500000">19,500 KM</MenuItem>
                  <MenuItem value="20000000">20,000 KM</MenuItem>
                  <MenuItem value="20500000">20,500 KM</MenuItem>
                  <MenuItem value="21000000">21,000 KM</MenuItem>
                  <MenuItem value="21500000">21,500 KM</MenuItem>
                  <MenuItem value="22000000">22,000 KM</MenuItem>
                  <MenuItem value="22500000">22,500 KM</MenuItem>
                  <MenuItem value="23000000">23,000 KM</MenuItem>
                  <MenuItem value="23500000">23,500 KM</MenuItem>
                  <MenuItem value="24000000">24,000 KM</MenuItem>
                  <MenuItem value="24500000">24,500 KM</MenuItem>
                  <MenuItem value="25000000">25,000 KM</MenuItem>
                  <MenuItem value="25500000">25,500 KM</MenuItem>
                  <MenuItem value="26000000">26,000 KM</MenuItem>
                  <MenuItem value="26500000">26,500 KM</MenuItem>
                  <MenuItem value="27000000">27,000 KM</MenuItem>
                  <MenuItem value="27500000">27,500 KM</MenuItem>
                  <MenuItem value="28000000">28,000 KM</MenuItem>
                  <MenuItem value="28500000">28,500 KM</MenuItem>
                  <MenuItem value="29000000">29,000 KM</MenuItem>
                  <MenuItem value="29500000">29,500 KM</MenuItem>
                  <MenuItem value="30000000">30,000 KM</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <TextField
                margin="normal"
                className={classes.textfieldClass}
                type="number"
                value={
                  AddNewObject?.attributes?.period
                    ? Number(Math.round(AddNewObject.attributes.period))
                    : 0
                }
                onChange={(event) => {
                  if (!isNaN(event.target.value)) {
                    setAddNewObject({
                      ...AddNewObject,
                      attributes: {
                        ...AddNewObject?.attributes,
                        period: event.target.value,
                      },
                    });
                  }
                }}
                // value={formatDistance(AddNewObject.start,distanceUnit)}
                label="Hours"
                variant="filled"
                fullWidth
              />
            )}

            <FormControl variant="filled" margin="normal" fullWidth>
              <InputLabel>Select Notify Before</InputLabel>
              {AddNewObject.type == "odometer" ? (
                <Select
                  value={Number(AddNewObject?.attributes?.notify)}
                  onChange={(event) => {
                    setAddNewObject({
                      ...AddNewObject,
                      attributes: {
                        ...AddNewObject?.attributes,
                        notify: event.target.value,
                      },
                    });
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem value="100">100 KM</MenuItem>
                  <MenuItem value="200">200 KM</MenuItem>
                  <MenuItem value="300">300 KM</MenuItem>
                  <MenuItem value="400">400 KM</MenuItem>
                  <MenuItem value="500">500 KM</MenuItem>
                  <MenuItem value="600">600 KM</MenuItem>
                  <MenuItem value="700">700 KM</MenuItem>
                  <MenuItem value="800">800 KM</MenuItem>
                  <MenuItem value="900">900 KM</MenuItem>
                  <MenuItem value="1000">1000 KM</MenuItem>
                </Select>
              ) : (
                <Select
                  value={Number(AddNewObject?.attributes?.notify)}
                  onChange={(event) => {
                    setAddNewObject({
                      ...AddNewObject,
                      attributes: {
                        ...AddNewObject?.attributes,
                        notify: event.target.value,
                      },
                    });
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  <MenuItem value="50">50 Hr</MenuItem>
                  <MenuItem value="100">100 Hr</MenuItem>
                  <MenuItem value="150">150 Hr</MenuItem>
                  <MenuItem value="200">200 Hr</MenuItem>
                  <MenuItem value="250">250 Hr</MenuItem>
                  <MenuItem value="300">300 Hr</MenuItem>
                  <MenuItem value="350">350 Hr</MenuItem>
                  <MenuItem value="400">400 Hr</MenuItem>
                  <MenuItem value="450">450 Hr</MenuItem>
                  <MenuItem value="500">500 Hr</MenuItem>
                </Select>
              )}
            </FormControl>
            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">Notification Type</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        NotificationObject.notificators.indexOf("mail") > -1
                      }
                      onChange={handleNotificationChange}
                      name="mail"
                    />
                  }
                  label="Mail"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        NotificationObject.notificators.indexOf("web") > -1
                      }
                      onChange={handleNotificationChange}
                      name="web"
                    />
                  }
                  label="Web"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        NotificationObject.notificators.indexOf("sms") > -1
                      }
                      onChange={handleNotificationChange}
                      name="sms"
                    />
                  }
                  label="SMS"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        NotificationObject.notificators.indexOf("firebase") > -1
                      }
                      onChange={handleNotificationChange}
                      name="firebase"
                    />
                  }
                  label="Push"
                />
              </FormGroup>
            </FormControl>

            {AddNewObject.name &&
              AddNewObject.type &&
              (IsEdit ? (
                <div className={classes.btn}>
                  <FormControl margin="normal" fullWidth>
                    <ButtonGroup color="primary" orientation="vertical">
                      <Button
                        onClick={() => EditMaintenances()}
                        color="primary"
                      >
                        Save Changes
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup color="primary" orientation="vertical">
                      <Button onClick={() => formReset()} color="secondary">
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </div>
              ) : (
                <FormControl margin="normal" fullWidth>
                  <ButtonGroup color="primary" orientation="vertical">
                    <Button
                      onClick={() => AddMaintenances()}
                      color="secondary"
                      startIcon={<Save />}
                    >
                      Save
                    </Button>
                  </ButtonGroup>
                </FormControl>
              ))}
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} md={9} lg={10}>
        <MaterialTable
          columns={[
            {
              title: "Vehicle",
              field: "vehicleName",
              editable: "never",
              render: (rowData) => rowData.vehicleName,
            },
            {
              title: "Maintenance Name",
              field: "name",
              render: (rowData) => rowData.name,
            },
            {
              title: t("sharedType"),
              field: "type",
              render: (rowData) => rowData.type,
              lookup: { odometer: "odometer", hours: "hours" },
            },
            {
              title: t("maintenanceStart"),
              field: "start",
              render: (rowData) => {
                if (rowData.type === "hours") {
                  return (
                    `${(Math.round(rowData.start) / 1000 / 60 / 60).toFixed(
                      2
                    )} ` + "Hr"
                  );
                }
                return `${Math.round(rowData.start * 0.001)} ` + "Km";
              },
            },

            {
              title: t("maintenancePeriod"),
              field: "attributes.period",
              render: (rowData) => {
                if (rowData.type === "hours") {
                  return `${rowData?.attributes?.period} ` + "Hr";
                }
                return `${rowData?.attributes?.period} ` + "KM";
              },
            },
            {
              title: "Notify Before",
              field: "attributes.notify",
              render: (rowData) => rowData?.attributes?.notify,
            },
            {
              title: "Action",
              field: "Edit / Delete",
              render: (rowData) => {
                const EditCB = async (e) => {
                  setSelectedRow(rowData.tableData.id);
                  setIsEdit(true);
                  setShowAddMaintenanceContainer(true);
                  setAddNewObject({
                    name: rowData.name,
                    type: rowData.type,
                    start: rowData?.start ? rowData.start : 0,
                    period: rowData.period,
                    id: rowData.id,
                    attributes: rowData.attributes,
                  });
                  e.stopPropagation();
                };

                const DeleteCb = async (e) => {
                  const response = await fetch(
                    `/api/maintenance/${rowData.id}`,
                    {
                      method: "DELETE",
                    }
                  );
                  if (response) {
                    // formReset();
                    e.stopPropagation();
                    handleClick();
                  }
                  e.stopPropagation();
                };
                return (
                  <div style={{ display: "flex" }}>
                    <EditView cb={EditCB} />
                    <DeleteView cb={DeleteCb} />
                  </div>
                );
              },
            },
          ]}
          data={items}
          title=""
          onRowClick={(event, rowData) => {
            //  onMenuClick(event.currentTarget, rowData.tableData.id)
            // setAddNewObject({...AddNewObject,name:rowData.name,type:rowData.type,start:rowData.start,period:rowData.period,id:rowData.id});
          }}
          onChangePage={() => {
            formReset();
          }}
          icons={{
            FirstPage: React.forwardRef((props, ref) => (
              <FirstPage style={{ color: "white" }} {...props} ref={ref} />
            )),
            LastPage: React.forwardRef((props, ref) => (
              <LastPage style={{ color: "white" }} {...props} ref={ref} />
            )),
            NextPage: React.forwardRef((props, ref) => (
              <ChevronRight style={{ color: "white" }} {...props} ref={ref} />
            )),
            PreviousPage: React.forwardRef((props, ref) => (
              <ChevronLeft style={{ color: "white" }} {...props} ref={ref} />
            )),
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#1F1D2B",
              color: "#FFFFFF",
            },
            sorting: true,
            paging: items.length > 10,
            pageSize: 10,
            pageSizeOptions: [10],
            /* maxBodyHeight: calcHeight+'px', */
            defaultSort: "desc",
            rowStyle: (rowData) => ({
              backgroundColor:
                SelectedRow === rowData.tableData.id ? "#0d0d0d" : "",
              display: rowData.id ? "table-row" : "none",
            }),
          }}
          style={{
            color: "#57c7d4",
          }}
        />
      </Grid>
    </Grid>
  );
};

const MaintenacesPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>
        <EditCollectionView
          content={MaintenancesView}
          editPath="/settings/maintenance"
          endpoint="maintenance"
        />
      </div>
    </div>
  );
};

export default MaintenacesPage;
