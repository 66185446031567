import {
  Grid,
  InputLabel,
  TextField,
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  AddCircle,
  CheckBox,
  Edit,
  RadioSharp,
  Save,
} from "@mui/icons-material";
import mapboxgl from "mapbox-gl";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { styleMapbox1, styleMapbox } from "common/util/mapStyle";
import { useEffectAsync, DialogBox } from "reactHelper";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { useAttributePreference } from "./common/util/preferences";
import { useTranslation } from "common/components/LocalizationProvider";
import SelectField from "common/components/SelectField";
import _, { set } from "lodash";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Autocomplete from "react-google-autocomplete";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import theme from "./map/draw/theme";
import { polygon } from "@turf/turf";
import { geofenceToFeatureV2, geometryToArea } from "./map/core/mapUtil";

const desiredZoom = [
  { area: 600, zoom: 13 },
  { area: 800, zoom: 13 },
  { area: 1000, zoom: 13 },
  { area: 1100, zoom: 13 },
  { area: 1200, zoom: 13 },
  { area: 1300, zoom: 13 },
  { area: 1400, zoom: 13 },
  { area: 1500, zoom: 13 },
  { area: 2000, zoom: 12 },
  { area: 3000, zoom: 12 },
  { area: 25000, zoom: 9 },
  { area: 50000, zoom: 8 },
  { area: 100000, zoom: 7 },
];
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    // padding: "15px",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
    },
  },
  autocompleteLabel: {
    fontSize: "14px",
    marginTop: "15px",
    background: "#1d1b28",
    padding: "5px 0px 0px 10px",
  },
  autocompleteInput: {
    color: "#fff",
    background: "#1d1b28",
    border: "0px",
    borderBottom: "1px solid #000",
    padding: "5px 20px 10px 10px",
    fontSize: "17px",
    "&:focus": {
      borderColor: theme.palette.primary.main, // Changes border color on focus
      boxShadow: `0 0 5px ${theme.palette.primary.main}`, // Adds a glow effect on focus
    },
  },
  content: {
    flex: 1,
    overflow: "auto",
    // padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  mapstyle: {
    "& svg": {
      height: "30px",
    },
  },
}));
// export const EditView = ({ cb }) => {
//   const edit = (e) => {
//     e.stopPropagation();
//     cb(e);
//   };
//   return (
//     <div
//       style={{
//         padding: "3px",
//         margin: "0px 10px",
//         borderRadius: "10px",
//         cursor: "pointer",
//       }}
//     >
//       <EditOutlinedIcon onClick={(e) => edit(e)} />
//     </div>
//   );
// };

export const DeleteView = ({ cb }) => {
  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: "Description",
    title: "titile",
    type: "conform",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });
  const deleteRow = (e) => {
    const fun = async () => {
      setDialog({ isOpen: false });
      await cb(e);
    };

    e.stopPropagation();
    setDialog({
      isOpen: true,
      handleClose: () => setDialog({ isOpen: false }),
      handleDisagreeClose: () => {
        setDialog({ isOpen: false });
        // setMileage(ValueBuffer);
      },
      handleSuccess: fun,
      decription: "Are you sure you want to delete this record  ?",
      title: "Alert !!",
      type: "conform",
      confmBtnText: "Yes  ",
      closeBtnText: "No ",
    });
    // setDialog({...Dialog,handleSuccess:() => {cb(e)}})
  };

  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      <DeleteOutlineOutlinedIcon onClick={(e) => deleteRow(e)} />
    </div>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GeofencesTab = ({ height }) => {
  const classes = useStyles();
  const t = useTranslation();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isDeleteTriggered, setIsDeleteTriggered] = useState(false);
  const [SelectedRow, setSelectedRow] = useState();
  const [items, setItems] = useState([]);
  const [Type, setType] = useState("Current Location");
  const [CurrentDeviceCoordinates, setCurrentDeviceCoordinates] = useState();
  const [ApiCoordinates, setApiCoordinates] = useState();
  const [Address, setAddress] = useState();
  const [IsEdit, setIsEdit] = useState(false);
  const [EnterFence, setEnterFence] = useState(false);
  const [showPhoneDialog, setShowPhoneDialog] = useState(false);
  const [SMS, setSMS] = useState(true);
  const [Push, setPush] = useState(true);
  const [Web, setWeb] = useState(true);
  const [Mail, setMail] = useState(true);
  const [EnterFenceID, setEnterFenceID] = useState();
  const [LeaveFenceID, setLeaveFenceID] = useState();
  const [LeaveFence, setLeaveFence] = useState(false);
  const [polygonType, setPolygonType] = useState("Circle");
  const [polygonData, setPolygonData] = useState();
  const user = useSelector((state) => state.session.user);
  const userId = useSelector(
    (state) => state.session.user && state.session.user.id
  );
  const phone = useSelector(
    (state) => state.session.user && state.session.user.phone
  );

  const handleSuccessPhoneCallback = () => {
    alert("handle success phone dialog");
  };

  const handleErrorPhoneCallback = () => {
    setShowPhoneDialog(false);
    EditAddGeofences();
  };

  const [GeoFencesData, setGeoFencesData] = useState({
    id: 0,
    name: "",
    description: "",
    area: "600",
    calendarId: 0,
    attributes: {},
  });
  const [NotificationObject, setNotificationObject] = useState({
    id: -1,
    always: false,
    type: null,
    calendarId: 0,
    notificators: "mail,web,sms, firebase",
  });

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  const formReset = () => {
    setIsEdit(false);
    setSelectedRow(null);
    setIsFormVisible(false);
    setPolygonData(null);
    setPolygonType("Circle");
    setGeoFencesData({
      id: 0,
      name: "",
      description: "",
      area: "600",
      calendarId: 0,
      attributes: {},
    });
  };

  const handleDraw = (data) => {
    setPolygonData(geometryToArea(data?.geometry));
  };

  const selectedDevicePositionId = useSelector((state) => {
    if (state.devices.selectedId) {
      const position =
        state.session.positions[state.devices.selectedId] || null;
      if (position) {
        return position.id;
      }
    }
  });

  useEffectAsync(async () => {
    if (selectedDeviceId) {
      const res = await fetch(
        `/api/notifications?deviceId=${selectedDeviceId}`,
        {
          method: "GET",
        }
      );

      if (res.ok) {
        const data = await res.json();
        let geofenceEnterNotification = _.orderBy(
          _.filter(data, { type: "geofenceEnter" }),
          ["id"],
          ["desc"]
        );
        let geofenceEnterOutput = _.first(geofenceEnterNotification);

        if (!!geofenceEnterOutput) {
          setEnterFenceID(geofenceEnterOutput.id);
          setEnterFence(true);

          if (!!geofenceEnterOutput.notificators) {
            let notificatorsTemp = geofenceEnterOutput.notificators.split(",");

            setMail(notificatorsTemp.includes("mail"));
            setWeb(notificatorsTemp.includes("web"));
            setSMS(notificatorsTemp.includes("sms"));
            setPush(notificatorsTemp.includes("firebase"));
          }
        }

        let geofenceExitNotification = _.orderBy(
          _.filter(data, { type: "geofenceExit" }),
          ["id"],
          ["desc"]
        );
        let geofenceExitOutput = _.first(geofenceExitNotification);

        if (!!geofenceExitOutput) {
          setLeaveFenceID(geofenceExitOutput.id);
          setLeaveFence(true);

          if (geofenceExitOutput.notificators) {
            let notificatorsTemp = geofenceExitOutput.notificators.split(",");
            setMail(notificatorsTemp.includes("mail"));
            setWeb(notificatorsTemp.includes("web"));
            setSMS(notificatorsTemp.includes("sms"));
            setPush(notificatorsTemp.includes("firebase"));
          }
        }
      }
    }
  }, [selectedDeviceId]);

  const GenerateNotificationObject = () => {
    const notificatorItems = [];

    if (SMS) {
      notificatorItems.push("sms");
    }

    if (Mail) {
      notificatorItems.push("mail");
    }

    if (Web) {
      notificatorItems.push("web");
    }

    if (Push) {
      notificatorItems.push("firebase");
    }

    const notificationData = {
      id: selectedDeviceId || -1,
      always: false,
      type: null,
      calendarId: 0,
      notificators: notificatorItems.join(","),
    };
    setNotificationObject(notificationData);
  };

  useEffect(() => {
    GenerateNotificationObject();
  }, [SMS, Mail, Web, Push, selectedDeviceId]);

  const F_EnterFence = async () => {
    if (EnterFence) {
      if (EnterFenceID) {
        await fetch(`/api/notifications/${EnterFenceID}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...NotificationObject,
            id: EnterFenceID,
            type: "geofenceEnter",
          }),
        })
          .then((res) => res.json())
          .then(async (result) => {
            setEnterFenceID(result.id);
            if (result) {
              GetAllGeofences();
            }
          });
      } else {
        await fetch("/api/notifications", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...NotificationObject,
            type: "geofenceEnter",
          }),
        })
          .then((res) => res.json())
          .then(async (result) => {
            setEnterFenceID(result.id);
            setEnterFence(true);

            const respe = await fetch("/api/permissions", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                deviceId: selectedDeviceId,
                notificationId: result.id,
              }),
            });
            if (respe) {
              GetAllGeofences();
            }
          });
      }
    } else {
      if (EnterFenceID) {
        await fetch(
          `/api/notifications/` + EnterFenceID + `?_dc=${new Date().getTime()}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ...NotificationObject,
              type: "geofenceEnter",
              id: EnterFenceID,
            }),
          }
        );

        const respe = await fetch("/api/permissions", {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: selectedDeviceId,
            notificationId: EnterFenceID,
          }),
        });
      }
      GetAllGeofences();
    }
  };

  const F_LeaveFence = async () => {
    if (LeaveFence) {
      if (LeaveFenceID) {
        await fetch(`/api/notifications/${LeaveFenceID}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ...NotificationObject,
            id: LeaveFenceID,
            type: "geofenceExit",
          }),
        })
          .then((res) => res.json())
          .then(async (result) => {
            setLeaveFenceID(result.id);
            if (result) {
              GetAllGeofences();
            }
          });
      } else {
        await fetch("/api/notifications", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...NotificationObject, type: "geofenceExit" }),
        })
          .then((res) => res.json())
          .then(async (result) => {
            setLeaveFenceID(result.id);
            setLeaveFence(true);

            const respe = await fetch("/api/permissions", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                deviceId: selectedDeviceId,
                notificationId: result.id,
              }),
            });
            if (respe) {
              GetAllGeofences();
            }
          });
      }
    } else {
      if (LeaveFenceID) {
        await fetch(
          `/api/notifications/` + LeaveFenceID + `?_dc=${new Date().getTime()}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ...NotificationObject,
              type: "geofenceExit",
              id: LeaveFenceID,
            }),
          }
        );

        const respe = await fetch("/api/permissions", {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: selectedDeviceId,
            notificationId: LeaveFenceID,
          }),
        });
      }
    }

    GetAllGeofences();
  };

  const EditGeofences = async () => {
    const newObj = { ...GeoFencesData };

    if (
      !!CurrentDeviceCoordinates.latitude &&
      !!CurrentDeviceCoordinates.longitude
    ) {
      newObj.attributes.latitude = CurrentDeviceCoordinates.latitude;
      newObj.attributes.longitude = CurrentDeviceCoordinates.longitude;
    }

    newObj.area =
      polygonType == "Circle"
        ? `CIRCLE(${CurrentDeviceCoordinates.latitude} ${CurrentDeviceCoordinates.longitude},${newObj.area})`
        : polygonData;

    const response = await fetch(`/api/geofences/${newObj.id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newObj),
    });

    if (response.ok) {
      formReset();

      GetAllGeofences();
      F_EnterFence();
      F_LeaveFence();
    }
  };

  const EditAddGeofences = () => {
    if (IsEdit) {
      EditGeofences();
    } else {
      AddGeofences();
    }
  };

  const validatePhoneNumber = () => {
    if (SMS && (!phone || phone.length == 0)) {
      setShowPhoneDialog(true);
      setSMS(false);
      GenerateNotificationObject();
    } else {
      EditAddGeofences();
    }
  };

  const AddGeofences = async () => {
    const newObj = { ...GeoFencesData };
    newObj.attributes.polygonType = polygonType;
    newObj.attributes.latitude = CurrentDeviceCoordinates.latitude;
    newObj.attributes.longitude = CurrentDeviceCoordinates.longitude;
    newObj.area =
      polygonType == "Circle"
        ? `CIRCLE(${CurrentDeviceCoordinates.latitude} ${CurrentDeviceCoordinates.longitude},${newObj.area})`
        : polygonData;

    const response = await fetch("/api/geofences", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newObj),
    });
    // debugger;
    if (response.ok) {
      const resp = await response.json();

      const respe = await fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          deviceId: selectedDeviceId,
          geofenceId: resp.id,
        }),
      });
      if (respe.ok) {
        formReset();
        GetAllGeofences();
        F_EnterFence();
        F_LeaveFence();
      }
    }
  };

  const GetAllGeofences = async (afterDelete = false) => {
    const response = await fetch(`/api/geofences?deviceId=${selectedDeviceId}`);
    if (response.ok) {
      setItems(await response.json());
    }
  };

  const getAddress = (longitude, latitude, setOnlyAddress) => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_LIVE_MAP_BOX_TOKEN}`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setAddress(result.features[0].place_name);
          if (!setOnlyAddress && Type == "Current Location") {
            setGeoFencesData({
              ...GeoFencesData,
              description: result.features[0].place_name,
            });
          }
        },
        (error) => {
          //console.log(error);
        }
      );
  };

  useEffectAsync(async () => {
    if (selectedDevicePositionId) {
      if (Type === "Current Location") {
        const response = await fetch(
          `/api/positions?id=${selectedDevicePositionId}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        if (response.ok) {
          const items = await response.json();
          getAddress(items[0].longitude, items[0].latitude);

          setCurrentDeviceCoordinates(items[0]);
        }
      }
    } else {
      setCurrentDeviceCoordinates({});
    }
    await GetAllGeofences();
  }, [selectedDevicePositionId, isFormVisible, Type]);

  return (
    <Paper className={classes.form}>
      <Snackbar
        open={showPhoneDialog}
        autoHideDuration={6000}
        onClose={() => {
          setShowPhoneDialog(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowPhoneDialog(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Please enter phone number in accounts
        </Alert>
      </Snackbar>
      <ButtonGroup>
        {isFormVisible ? (
          <Button
            color="secondary"
            // startIcon={<closeicon />}
            onClick={() => {
              setIsFormVisible(false);
            }}
          >
            Close
          </Button>
        ) : (
          <Button
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => {
              formReset();
              setGeoFencesData({
                ...GeoFencesData,
                id: 0,
                name: "",
                description: "",
                area: "600",
                calendarId: 0,
                attributes: {},
              });
              setIsFormVisible(true);
            }}
          >
            Add
          </Button>
        )}
      </ButtonGroup>

      <div className={classes.content}>
        <Grid container>
          {isFormVisible && !isDeleteTriggered && (
            <>
              <Grid item xs={12} md={3} lg={3}>
                <>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Select Type</InputLabel>
                    <Select
                      value={Type}
                      onChange={(event) => {
                        const waiting = () => {
                          if (!CurrentDeviceCoordinates.longitude) {
                            setTimeout(waiting, 200);
                          } else {
                            setType(event.target.value);
                          }
                        };
                        waiting();
                      }}
                      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    // value={GeoFencesData.description}
                    >
                      <MenuItem value="Current Location">
                        Current Location
                      </MenuItem>
                      <MenuItem value="Search Location">
                        Search Location
                      </MenuItem>
                    </Select>
                    {Type == "Search Location" && (
                      <>
                        <label className={classes.autocompleteLabel}>
                          Search for Location
                        </label>
                        <Autocomplete
                          className={classes.autocompleteInput}
                          apiKey={
                            process.env.REACT_APP_LIVE_GOOGLE_MAPS_API_KEY
                          }
                          options={{
                            types: ["geocode"],
                            componentRestrictions: { country: "au" },
                          }}
                          onPlaceSelected={(place) => {
                            setAddress(place.formatted_address);
                            setCurrentDeviceCoordinates({
                              latitude: place.geometry.location.lat(),
                              longitude: place.geometry.location.lng(),
                            });
                            setGeoFencesData({
                              ...GeoFencesData,
                              description: place.formatted_address,
                            });
                          }}
                        />
                      </>
                    )}
                  </FormControl>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Select Area Type</InputLabel>
                    <Select
                      value={polygonType}
                      onChange={(event) => {
                        setPolygonType(event.target.value);
                      }}
                      readOnly={IsEdit}
                      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    // value={GeoFencesData.description}
                    >
                      <MenuItem value="Circle">Circle</MenuItem>
                      <MenuItem value="Custom">Custom</MenuItem>
                    </Select>
                  </FormControl>
                  {polygonType == "Circle" && (
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>Select Radius</InputLabel>
                      <Select
                        value={GeoFencesData.area}
                        onChange={(e) =>
                          setGeoFencesData({
                            ...GeoFencesData,
                            area: e.target.value,
                          })
                        }
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        {/* <MenuItem value="200">200 metres</MenuItem> */}
                        <MenuItem value="600">600 metres</MenuItem>
                        <MenuItem value="800">800 metres</MenuItem>
                        <MenuItem value="1000">1,000 metres</MenuItem>
                        <MenuItem value="1100">1,100 metres</MenuItem>
                        <MenuItem value="1200">1,200 metres</MenuItem>
                        <MenuItem value="1300">1,300 metres</MenuItem>
                        <MenuItem value="1400">1,400 metres</MenuItem>
                        <MenuItem value="1500">1,500 metres</MenuItem>
                        <MenuItem value="2000">2,000 metres</MenuItem>
                        <MenuItem value="3000">3,000 metres</MenuItem>
                        <MenuItem value="25000">25 kilometres</MenuItem>
                        <MenuItem value="50000">50 kilometres</MenuItem>
                        <MenuItem value="100000">100 kilometres</MenuItem>
                      </Select>
                    </FormControl>
                  )}

                  {selectedDeviceId && (
                    <>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={EnterFence}
                              onChange={(e) => setEnterFence(e.target.checked)}
                              name="EnterFence"
                            />
                          }
                          label="Enter Fence"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={LeaveFence}
                              onChange={(e) => setLeaveFence(e.target.checked)}
                              name="LeaveFence"
                            />
                          }
                          label="Leave Fence"
                        />
                      </FormGroup>
                      <br />
                      <FormControl
                        sx={{ m: 3 }}
                        component="fieldset"
                        variant="standard"
                      >
                        <FormLabel component="legend">
                          Notification Type
                        </FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={Mail}
                                onChange={(e) => setMail(e.target.checked)}
                                name="mail"
                              />
                            }
                            label="Mail"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={Web}
                                onChange={(e) => setWeb(e.target.checked)}
                                name="web"
                              />
                            }
                            label="Web"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={SMS}
                                onChange={(e) => setSMS(e.target.checked)}
                                name="sms"
                              />
                            }
                            label="SMS"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={Push}
                                onChange={(e) => setPush(e.target.checked)}
                                name="firebase"
                              />
                            }
                            label="Push"
                          />
                        </FormGroup>
                      </FormControl>
                    </>
                  )}

                  {user?.attributes?.calendar && (
                    <SelectField
                      margin={"normal"}
                      value={GeoFencesData.calendarId || 0}
                      variant="filled"
                      fullWidth
                      onChange={(event) =>
                        setGeoFencesData({
                          ...GeoFencesData,
                          calendarId: Number(event.target.value),
                        })
                      }
                      endpoint="/api/calendars"
                      label={t("sharedCalendar")}
                    />
                  )}

                  <TextField
                    margin="normal"
                    label="Geofence name"
                    variant="filled"
                    onChange={(e) =>
                      setGeoFencesData({
                        ...GeoFencesData,
                        name: e.target.value,
                      })
                    }
                    value={GeoFencesData.name}
                    fullWidth
                  />
                  {GeoFencesData.name &&
                    Address &&
                    GeoFencesData.area &&
                    (IsEdit ? (
                      <div className={classes.btn}>
                        <ButtonGroup>
                          <Button
                            onClick={() => validatePhoneNumber()}
                            color="primary"
                            startIcon={<Edit />}
                          >
                            Save Changes
                          </Button>
                        </ButtonGroup>
                        {/* <ButtonGroup>
                          <Button onClick={() => formReset()} color="secondary">
                            Cancel
                          </Button>
                        </ButtonGroup> */}
                      </div>
                    ) : (
                      <div className={classes.btn}>
                        <ButtonGroup color="primary">
                          <Button
                            onClick={() => validatePhoneNumber()}
                            color="primary"
                            startIcon={<Save />}
                          >
                            Save
                          </Button>
                        </ButtonGroup>
                        {/* <ButtonGroup>
                          <Button onClick={() => formReset()} color="secondary">
                            Cancel
                          </Button>
                        </ButtonGroup> */}
                      </div>
                    ))}
                </>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <div style={{ height: height / 2, position: "relative" }}>
                  <MapContainer
                    key={
                      CurrentDeviceCoordinates
                        ? CurrentDeviceCoordinates.latitude +
                        "" +
                        polygonType +
                        "" +
                        GeoFencesData.area
                        : 0
                    }
                    height={height / 2}
                    Type={Type}
                    latitude={
                      CurrentDeviceCoordinates
                        ? CurrentDeviceCoordinates.latitude
                        : 0
                    }
                    longitude={
                      CurrentDeviceCoordinates
                        ? CurrentDeviceCoordinates.longitude
                        : 0
                    }
                    // radius={radius}
                    polygonType={polygonType}
                    polygonData={polygonData}
                    Address={Address}
                    geofence={GeoFencesData}
                    area={Number(GeoFencesData.area)}
                    onDraw={(data) => handleDraw(data)}
                  />
                </div>
              </Grid>
            </>
          )}
          <MaterialTable
            columns={[
              {
                title: "Geofence Name",
                field: "name",
                render: (rowData) => rowData.name,
              },
              {
                title: "Radius",
                field: "area",
                render: (rowData) => {
                  if (rowData.area.startsWith("CIRCLE")) {
                    let area = rowData.area
                      .split(")")[0]
                      .split(",")[1];
                    return area > 20000
                      ? area / 1000 + " kms"
                      : area + " m";
                  }
                },
              },
              {
                title: "Address",
                field: "description",
                render: (rowData) => rowData.description,
              },
              {
                title: "Action",
                field: "Delete",
                render: (rowData) => {
                  const DeleteCb = async (e) => {
                    setIsFormVisible(false);
                    setIsDeleteTriggered(true);
                    const response = await fetch(
                      `/api/geofences/${rowData.id}`,
                      {
                        method: "DELETE",
                      }
                    );
                    // await  fetch(ApiUrl + "/api/permissions", {
                    //   method: "DELETE",
                    //   headers: { "Content-Type": "application/json" },
                    //   body: JSON.stringify({
                    //     deviceId: selectedDeviceId,
                    //     geofenceId: rowData.id,
                    //   }),
                    // });
                    //
                    // await  fetch(ApiUrl + "/api/permissions", {
                    //   method: "DELETE",
                    //   headers: { "Content-Type": "application/json" },
                    //   body: JSON.stringify({
                    //     userId: userId,
                    //     geofenceId: rowData.id,
                    //   }),
                    // });

                    if (LeaveFenceID) {
                      await fetch(
                        `/api/notifications/${LeaveFenceID}?_dc=${new Date().getTime()}`,
                        {
                          method: "DELETE",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            ...NotificationObject,
                            type: "geofenceExit",
                            id: LeaveFenceID,
                          }),
                        }
                      );

                      // await fetch(ApiUrl + "/api/permissions", {
                      //   method: "DELETE",
                      //   headers: { "Content-Type": "application/json" },
                      //   body: JSON.stringify({
                      //     deviceId: selectedDeviceId,
                      //     notificationId: LeaveFenceID,
                      //   }),
                      // });
                      //
                      // await fetch(ApiUrl + "/api/permissions", {
                      //   method: "DELETE",
                      //   headers: { "Content-Type": "application/json" },
                      //   body: JSON.stringify({
                      //     deviceId: selectedDeviceId,
                      //     userId: LeaveFenceID,
                      //   }),
                      // });
                      //
                      // const respe =await fetch(ApiUrl + "/api/permissions", {
                      //   method: "DELETE",
                      //   headers: { "Content-Type": "application/json" },
                      //   body: JSON.stringify({
                      //     userId: userId,
                      //     notificationId: LeaveFenceID,
                      //   }),
                      // });
                      //
                      // await fetch(ApiUrl + "/api/permissions", {
                      //   method: "DELETE",
                      //   headers: { "Content-Type": "application/json" },
                      //   body: JSON.stringify({
                      //     deviceId: selectedDeviceId,
                      //     userId: userId,
                      //   }),
                      // });
                    }

                    if (EnterFenceID) {
                      await fetch(
                        `/api/notifications/${EnterFenceID}?_dc=${new Date().getTime()}`,
                        {
                          method: "DELETE",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            ...NotificationObject,
                            type: "geofenceEnter",
                            id: EnterFenceID,
                          }),
                        }
                      );

                      // const respe =await  fetch(ApiUrl + "/api/permissions", {
                      //   method: "DELETE",
                      //   headers: { "Content-Type": "application/json" },
                      //   body: JSON.stringify({
                      //     deviceId: selectedDeviceId,
                      //     notificationId: EnterFenceID,
                      //   }),
                      // });
                      //
                      //
                      //
                      // await fetch(ApiUrl + "/api/permissions", {
                      //   method: "DELETE",
                      //   headers: { "Content-Type": "application/json" },
                      //   body: JSON.stringify({
                      //     deviceId: selectedDeviceId,
                      //     userId: EnterFenceID,
                      //   }),
                      // });
                      //
                      // await fetch(ApiUrl + "/api/permissions", {
                      //   method: "DELETE",
                      //   headers: { "Content-Type": "application/json" },
                      //   body: JSON.stringify({
                      //     userId: userId,
                      //     notificationId: EnterFenceID,
                      //   }),
                      // });
                      //
                      // await fetch(ApiUrl + "/api/permissions", {
                      //   method: "DELETE",
                      //   headers: { "Content-Type": "application/json" },
                      //   body: JSON.stringify({
                      //     deviceId: selectedDeviceId,
                      //     userId: userId,
                      //   }),
                      // });
                    }
                    if (response) {
                      formReset();
                      setIsFormVisible(false);
                      setIsDeleteTriggered(false);
                      e.stopPropagation();

                      GetAllGeofences();
                    }
                    e.stopPropagation();
                  };
                  return (
                    <div style={{ display: "flex" }}>
                      <DeleteView cb={DeleteCb} />
                    </div>
                  );
                },
              },
            ]}
            data={items.sort((a, b) =>
              a.id < b.id ? 1 : b.id < a.id ? -1 : 0
            )}
            title=""
            onRowClick={(event, rowData) => {
              setPolygonData(rowData.area);

              if (rowData?.attributes?.polygonType == "Custom") {

                const area = rowData.area;
                const latitude = rowData?.attributes?.latitude;
                const longitude = rowData?.attributes?.longitude;
                getAddress(longitude, latitude, true);
                setCurrentDeviceCoordinates({
                  latitude: Number(latitude),
                  longitude: Number(longitude),
                });
                setPolygonType("Custom");
                setGeoFencesData({
                  ...GeoFencesData,
                  id: rowData.id,
                  name: rowData.name,
                  description: rowData.description,
                  area,
                  calendarId: rowData.calendarId,
                  attributes: rowData.attributes,
                });
              } else {
                const area = rowData.area.split(")")[0].split(",")[1];
                const latitude = rowData.area
                  .split("(")[1]
                  .split(")")[0]
                  .split(",")[0]
                  .split(" ")[0];
                const longitude = rowData.area
                  .split("(")[1]
                  .split(")")[0]
                  .split(",")[0]
                  .split(" ")[1];
                getAddress(longitude, latitude, true);
                setPolygonType("Circle");
                setCurrentDeviceCoordinates({
                  latitude: Number(latitude),
                  longitude: Number(longitude),
                });

                setGeoFencesData({
                  ...GeoFencesData,
                  id: rowData.id,
                  name: rowData.name,
                  description: rowData.description,
                  area,
                  calendarId: rowData.calendarId,
                  attributes: {},
                });
              }

              setSelectedRow(rowData.tableData.id);
              setIsEdit(true);
              setIsFormVisible(true);
              setType("Search Location");
              setAddress(rowData.description);
            }}
            options={{
              headerStyle: {
                backgroundColor: "#1F1D2B",
                color: "#FFFFFF",
              },
              sorting: true,
              paging: false,
              /* maxBodyHeight: calcHeight+'px', */
              defaultSort: "desc",
              rowStyle: (rowData) => ({
                backgroundColor:
                  SelectedRow === rowData.tableData.id ? "#0d0d0d" : "",
              }),
              search: true,
            }}
            style={{
              color: "#57c7d4",
              width: "100%",
              // overflowY: "scroll",
              // height: height - 230,
            }}
          />
        </Grid>
      </div>
    </Paper>
  );
};

export const MapContainer = ({
  height,
  Type,
  latitude,
  longitude,
  area,
  Address,
  polygonType,
  polygonData,
  geofence,
  onDraw = (data) => { },
}) => {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const classes = useStyles();
  const mapboxAccessToken = useAttributePreference("mapboxAccessToken");

  const draw = useMemo(
    () =>
      new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          line_string: false,
          trash: true,
        },
        userProperties: true,
        styles: [
          ...theme,
          {
            id: "gl-draw-title",
            type: "symbol",
            filter: ["all"],
            layout: {
              "text-field": "{user_name}",
              "text-font": ["Roboto Regular"],
              "text-size": 12,
            },
            paint: {
              "text-halo-color": "white",
              "text-halo-width": 1,
            },
          },
        ],
      }),
    []
  );

  const createGeoJSONCircle = (center, radiusInKm, points) => {
    if (!points) points = 64;

    const coords = {
      latitude: center[1],
      longitude: center[0],
    };

    const km = radiusInKm;

    const ret = [];
    const distanceX =
      km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;

    let theta;
    let x;
    let y;
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [ret],
            },
          },
        ],
      },
    };
  };

  // useEffect(() => {
  //   if(mapboxAccessToken && mapboxAccessToken.length > 0){
  //      mapboxgl.accessToken = window.atob(mapboxAccessToken);
  //   } else {
  //     mapboxgl.accessToken = '12345';
  //   }
  // }, [mapboxAccessToken])

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoidG9ueXBldGVyMSIsImEiOiJjamlxcmp5emEwbjM4M2ttc2pjb2VqZ3hiIn0.PfY3XOwn6KTV155XFfyvCg";

    if (map.current) return; // wait for map to initialize
    if (!mapContainer.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: styleMapbox1("tonypeter1/ckiguc7qu5xxv19pbji4pep9w"),
    });
    map.current.addControl(new mapboxgl.NavigationControl());

    if (polygonType == "Custom") {
      map.current.addControl(draw, "bottom-left");
    }

    map.current.on("draw.create", (event) => {
      const existingPolygons = draw
        .getAll()
        .features.filter((feature) => feature.geometry.type === "Polygon");

      // Remove all existing polygons before adding a new one
      existingPolygons.forEach((polygon, index) => {
        if (index == 1) {
          draw.delete(polygon.id);
          alert('Only one polygon is allowed');
        }
      });

      const drawnFeature = event.features[0]; // Get the drawn shape
      onDraw(drawnFeature);
    });

    map.current.on("draw.update", (event) => {
      const drawnFeature = event.features[0]; // Get the drawn shape
      onDraw(drawnFeature);
    });

    map.current.setCenter([longitude, latitude]);
    map.current.on("style.load", () => {
      if (polygonType != "Custom") {
        map.current.addSource(
          "polygon",
          createGeoJSONCircle([longitude, latitude], area / 1000)
        );

        map.current.addLayer({
          id: "polygon",
          type: "fill",
          source: "polygon",
          layout: {},
          paint: {
            "fill-color": "#2f9fff",
            "fill-opacity": 0.3,
          },
        });
      } else {
      }

      map.current.setZoom(13);
      map.current.setCenter([longitude, latitude]);
    });

    const marker = new mapboxgl.Marker()
      .setLngLat([longitude, latitude])
      .addTo(map.current);
  }, [longitude, latitude, Address, polygonType]);

  useEffect(() => {
    let timeOut = setTimeout(() => {
      if (longitude && latitude) {
        if (!!map.current) {
          if (polygonType != "Custom" && map.current.getSource("polygon")) {
            map.current
              .getSource("polygon")
              .setData(
                createGeoJSONCircle([longitude, latitude], area / 1000).data
              );

            const zoom = desiredZoom.filter((d) => d.area === area)[0].zoom
              ? desiredZoom.filter((d) => d.area === area)[0].zoom
              : 14;
            map.current.setZoom(zoom);
          } else {
            if (geofence?.id) {
              draw.add(geofenceToFeatureV2(geofence));
            }

            map.current.setZoom(14);
          }
          map.current.setCenter([longitude, latitude]);
        }
      }
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [area]);

  return (
    <>
      {Address &&
        (Type === "Current Location" || Type === "Search Location") && (
          <p
            style={{
              color: "white",
              marginLeft: 20,
              position: "absolute",
              top: "--8px",
              zIndex: 1,
              backgroundColor: "#1f1d2b",
              width: "fit-content",
              padding: "5px",
              left: "-12px",
              boxShadow: "0px 1px 8px black",
              borderRadius: "8px",
            }}
          >
            {Address}
          </p>
        )}

      {Address && (
        <div
          className={classes.mapstyle}
          id="map"
          ref={mapContainer}
          style={{
            height: `${height}px`,
            width: "100%",
            visibility:
              Type === "Current Location" || Type === "Search Location"
                ? "visible"
                : "hidden",
          }}
        />
      )}
    </>
  );
};

export default GeofencesTab;
